import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import Styled from "./style";
import PageHeader from "../../Components/PageHeader";
import {profileText, usersControlText} from "../../Constants/Text";
import { Text, Title } from "../../Components/Text";
import {
  CancelOutlined,
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  HelpOutline,
  Save,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { CatalogsResponse, userInbox, userType } from "../../Types/API";
import Button from "../../Components/Button";
import theme from "../../theme";
import TextField from "../../Components/Input/TextField";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import {
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";
import Alert from "../../Components/Alert";
import { ROLES, ROUTES } from "../../Constants";
import withButterfly from "../../HOC/withButterfly";
import useAxios from "../../Hooks/useAxios";
import { apiUrls } from "../../Constants/apiUrls";
import useAuth from "../../Hooks/useAuth";
import useProtectedRoute from "../../Hooks/useProtectedRoute";

const roleOptions = Object.values(ROLES);
const defaultData = {
  id: 0,
  fullName: "",
  email: "",
  active: true,
  role: roleOptions[0],
  inboxes: [],
  password: "",
  status: "Active",
};
const UserAdd = () => {
  const { userId } = useParams();
  const { allowedRoles }: { allowedRoles: string[] } = useOutletContext();
  const protectedRoute = useProtectedRoute(allowedRoles);

  const [userData, setUserData] = useState<userType>(defaultData);
  const {
    auth,
    configData,
    setConfigData,
  }: {
    auth: any;
    configData: CatalogsResponse;
    setConfigData: (arg: CatalogsResponse) => void;
  } = useAuth();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<string[]>([]);
  const [currentRole, setCurrentRole] = useState<string>("");
  const [showSavedAlert, setShowSavedAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { fetchData } = useAxios(true);

  const loadUserData = async (id: string) => {
    try {
      const res = await fetchData(apiUrls.users.userById(id));
      setUserData(res?.data as userType);
      setIsActive(!!res?.data?.active);
      setAccounts(getEmails(res?.data?.inboxes));
      setCurrentRole(res?.data?.role);
      reset(res?.data);
    } catch (e) {
      console.error(e);
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { isValid },
  } = useForm({ defaultValues: userData });

  const navigate = useNavigate();

  const breadcrumbs = [
    { label: usersControlText.title, url: "/usuarios" },
    {
      label: userId ? usersControlText.edit.edit : usersControlText.addUser,
      url: "",
    },
  ];
  const createUser = async () => {
    const { fullName, email, role, password, status } = getValues();
    const inboxIds = accounts.map(
      (email) => configData.emailInboxes.find((ei) => ei.email === email)?.id
    );
    const reqBody = {
      fullName,
      email,
      role,
      password,
      status,
      inboxes: inboxIds,
      createdBy: auth?.id,
    };
    try {
      const response = await fetchData(apiUrls.users.users, "POST", reqBody);
      if (!["200", "201"].includes(`${response?.status}`)) {
        throw Error;
      }
      setShowSavedAlert(true);
      setTimeout(() => setShowSavedAlert(false), 1500);
    } catch (e) {
      setShowErrorAlert(true);
      setTimeout(() => setShowErrorAlert(false), 1500);
      console.error(e);
    }
  };
  const getConfig = async () => {
    if (configData) {
      return;
    } else {
      try {
        const response = (await fetchData(apiUrls.catalog.catalogs, "GET")) as {
          data: CatalogsResponse;
        };
        setConfigData(response?.data);
        return response.data;
      } catch (error) {
        console.error(error);
      }
    }
  };
  const updateUser = async () => {
    if (!userId) return;
    const { fullName, role, password, status } = getValues();

    const inboxes = accounts.map(
      (email) => configData.emailInboxes.find((ei) => ei.email === email)?.id
    );
    const reqBody = {
      fullName,
      status,
      role,
      password,
      inboxes,
    };

    try {
      const response = await fetchData(
        apiUrls.users.userById(userId),
        "PUT",
        reqBody
      );
      if (!["200", "201"].includes(`${response?.status}`)) {
        throw Error;
      }
      setShowSavedAlert(true);
      setTimeout(() => setShowSavedAlert(false), 1500);
    } catch (error) {
      setShowErrorAlert(true);
      setTimeout(() => setShowErrorAlert(false), 1500);
      console.error(error);
    }
  };
  const onSubmit = async (data: any) => {
    if (userId) {
      await updateUser();
    } else {
      await createUser();
    }

    setTimeout(() => navigate(`/${ROUTES.users}`), 1500);
  };

  const getEmails = (inboxIds: userInbox[]) =>
    inboxIds.reduce((prev: string[], cv: userInbox) => {
      const currentEmail = configData?.emailInboxes?.find(
        (ei) => ei.id === cv.emailInboxId
      );
      if (currentEmail) {
        return [...prev, currentEmail.email];
      } else {
        return prev;
      }
    }, []);

  const loadConfig = async () => {
    await getConfig();
    if (userId) {
      loadUserData(userId);
      if (configData?.emailInboxes.length) {
        const activeEmails = configData?.emailInboxes
          .map((inbox) => inbox.email)
          .filter((email: string) => accounts.indexOf(email) !== -1);
        setAccounts(activeEmails);
      } else {
        setCurrentRole(roleOptions[0]);
      }
    }
  };

  useEffect(() => {
    protectedRoute.verifyPermissions();
  }, []);

  useEffect(() => {
    if (protectedRoute.isAllowed) {
      loadConfig();
    }
  }, [protectedRoute.isAllowed, configData]);

  const handleAccountsChange = (event: SelectChangeEvent<typeof accounts>) => {
    const {
      target: { value },
    } = event;

    setAccounts(typeof value === "string" ? value.split(",") : value);
  };
  const handleActiveChange = (event: SelectChangeEvent<string>) => {
    setIsActive(event.target.value === "Active");
    setValue("status", event.target.value);
  };
  const handleRoleChange = (event: SelectChangeEvent<typeof currentRole>) => {
    setCurrentRole(event.target.value);
    setValue("role", event.target.value);
  };
  const handleCancel = () => {
    reset(defaultData);
    setUserData(defaultData);
    navigate(`/${ROUTES.users}`);
  };

  return (
    <Styled.UserAdd>
      <Styled.Content>
        <PageHeader
          breadcrumbs={breadcrumbs}
          title={
            userId ? usersControlText.edit.title : usersControlText.addUser
          }
        />
        <Styled.Form onSubmit={handleSubmit(onSubmit)}>
          <Styled.UserInfo>
            <Styled.UserInfoItem>
              <Title type="3">{usersControlText.detail.info.name}</Title>
              <TextField
                type="text"
                placeholder="Nombre"
                {...register("fullName", {
                  required: "",
                  validate: (v: string) => v?.trim().length >= 1,
                })}
              />
            </Styled.UserInfoItem>
            <Styled.UserInfoItem>
              <Title type="3">{usersControlText.detail.info.email}</Title>
              <TextField
                type="text"
                placeholder="Correo"
                {...register("email", {
                  required: "",
                  validate: (v: string) => v?.trim().length >= 1,
                })}
              />
            </Styled.UserInfoItem>
            { !userId ? <Styled.UserInfoItem>
              <span style={{display: "flex", alignItems: "center"}}>
                <Title type="3">{usersControlText.detail.info.password} </Title>
                <Tooltip style={{padding: 0}} title={profileText.password.modal.tooltip}>
                  <IconButton>
                    <HelpOutline/>
                  </IconButton>
                </Tooltip>
              </span>
              <TextField
                  type={showPassword ? "text" : "password"}

                  placeholder="Contraseña"
                  InputProps={{
                    endAdornment: (
                        <InputAdornment
                            position="end"
                            onClick={() => setShowPassword((show) => !show)}
                        >
                          {showPassword ? (
                              <VisibilityOff style={theme.iconSize}/>
                          ) : (
                              <Visibility style={theme.iconSize}/>
                          )}
                        </InputAdornment>
                    ),
                  }}
                  {...register("password", {
                    validate: (p: string | undefined) =>
                        typeof p == "string" && /^(?=\S)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.\[\]{}()?\"!@#%&/\\,><'\`:;|_~+=-]).{8,}\S$/.test(p),
                  })}
              />
            </Styled.UserInfoItem> : < div/>}
            <Styled.UserInfoItem>
              <Title type="3">{usersControlText.detail.info.accounts}</Title>
              <Select
                value={accounts}
                multiple
                onChange={handleAccountsChange}
                renderValue={(selected) => (
                  <Text type="1">{`${selected}`}</Text>
                )}
              >
                {configData?.emailInboxes.map(({ email }) => (
                  <MenuItem key={email} value={email}>
                    {accounts.indexOf(email) !== -1 ? (
                      <CheckBoxOutlined style={theme.iconSize} />
                    ) : (
                      <CheckBoxOutlineBlank style={theme.iconSize} />
                    )}
                    <Text type="1">{email}</Text>
                  </MenuItem>
                ))}
              </Select>
            </Styled.UserInfoItem>
            <Styled.UserInfoItem>
              <Title type="3">{usersControlText.detail.info.role}</Title>
              <Select
                value={currentRole}
                {...register("role", {
                  onChange: handleRoleChange,
                })}
              >
                {roleOptions.map((role, index) => (
                  <MenuItem key={index} value={role}>
                    <Text type="1"> {role}</Text>
                  </MenuItem>
                ))}
              </Select>
            </Styled.UserInfoItem>
            <Styled.UserInfoItem>
              <Title type="3">{usersControlText.detail.info.status}</Title>

              <Select
                value={isActive ? "Active" : "Inactive"}
                onChange={handleActiveChange}
                defaultValue={!userData?.active ? "Active" : "Inactive"}
              >
                <MenuItem value={"Active"}>
                  <Text type="1"> {usersControlText.status.active}</Text>
                </MenuItem>
                <MenuItem value={"Inactive"}>
                  <Text type="1">{usersControlText.status.inactive}</Text>
                </MenuItem>
              </Select>
            </Styled.UserInfoItem>
          </Styled.UserInfo>
          <Styled.EditWrapper>
            <Button
              variant="outlined"
              startIcon={<CancelOutlined sx={theme.iconSize} />}
              onClick={handleCancel}
            >
              {usersControlText.edit.cancel}
            </Button>
            <Button
              variant="contained"
              startIcon={<Save sx={theme.iconSize} />}
              disabled={!isValid}
              type="submit"
            >
              {usersControlText.edit.save}
            </Button>
          </Styled.EditWrapper>
        </Styled.Form>
      </Styled.Content>
      <Alert
        show={showSavedAlert}
        message={`¡La información se ha guardado!`}
      />
      <Alert
        show={showErrorAlert}
        message={`Se ha presentado un error al guardar`}
        severity="error"
      />
    </Styled.UserAdd>
  );
};

export default withButterfly(UserAdd);
