import Styled from "./style";
import { ModalProps, Modal as MuiModal } from "@mui/material";

type ModalOptions = {modalContentStyle?: object} & ModalProps;

const Modal = (props: ModalOptions) => {
  return (
    <MuiModal {...props}>
      <Styled.Modal>
        <Styled.ModalContent style={{...props.modalContentStyle}}>{props.children}</Styled.ModalContent>
      </Styled.Modal>
    </MuiModal>
  );
};

export default Modal;
