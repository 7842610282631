export const formatDateFromMs = (ms: Date) => {
  const date = new Date(ms);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();

  return `${day}/${month}/${year}`;
};
const formatToHHMM = (ms: Date) => {
  const date = new Date(ms);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const ampm = hours >= 12 ? "pm" : "am";
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes} ${ampm}`;
};

const currencyFormat = (value: number | undefined) => {
  if (!value) {
    return 0;
  }
  return Intl.NumberFormat("es-US", {
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 5,
  }).format(value);
};

const getClaveCorta = (clave: string) => clave.substring(22, 41);

const formatTipoComprobante = (tipo: string) =>
  tipo.replaceAll(/([A-Z])/g, " $1");

const splitCaps = (inputString: string) => {
  return inputString.split(/(?=[A-Z])/).join(" ");
};

const removeFalsyValuesFromObject = (obj: { [key: string]: any }) => {
  const newObject = { ...obj };
  Object.keys(newObject).forEach((key: string) =>
    !newObject[key] ? delete newObject[key] : {}
  );
  return newObject;
};

const createParams = (obj: { [key: string]: any }) => {
  let params = "";
  for (const [key, value] of Object.entries(obj)) {
    if (value) params += `&${key}=${value}`;
  }
  return encodeURI(params);
};

const capitalize = (str: string = "") => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
const downloadFile = (
  content: string,
  fileName: string,
  contentType: string
) => {
  const blob = new Blob([content], { type: contentType });

  const a = document.createElement("a");
  const href = URL.createObjectURL(blob);
  a.href = href;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(href);
};

function formatDateYYYYMMDD(date: Date): string {
  const dateValid = Utils.isDateValid(date);
  if (dateValid){
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
  return "";
}

const isDateValid = (date: any):boolean => {
  try {
    // @ts-ignore
    return date instanceof Date && isFinite(date)
  } catch (e) {
    return false;
  }
}

const Utils = {
  formatTipoComprobante,
  formatDateFromMs,
  currencyFormat,
  getClaveCorta,
  formatToHHMM,
  splitCaps,
  createParams,
  removeFalsyValuesFromObject,
  downloadFile,
  capitalize,
  formatDateYYYYMMDD,
  isDateValid
};

export default Utils;
