import styled from "@emotion/styled";
import theme from "../../theme";

const Nav = styled.nav`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  background-color: ${theme.colors.white};
  position: relative;
  box-shadow: 6px 4px 60px rgba(81, 93, 86, 0.3);
  padding: 16px 0;
  z-index: 1;
  @media (min-width: ${theme.breakpoints.desktop}) {
    padding: 27px 0;
  }
`;

const Mobile = styled.div`
  grid-column: 2 / 12;
  display: flex;
  align-items: center;
  @media (min-width: ${theme.breakpoints.desktop}) {
    display: none;
  }
`;
const ProfileLink = styled.div`
  cursor: pointer;
`;
const Desktop = styled.div`
  grid-column: 2 / 12;
  display: none;
  @media (min-width: ${theme.breakpoints.desktop}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & ul {
      display: flex;
      list-style: none;
      & li {
        max-width: 16rem;
        padding: 0 1.7rem;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        & a {
          text-decoration: none;
          &[data-current="true"] {
            & > p {
              color: ${theme.colors.accent};
            }
            color: ${theme.colors.accent};
            border-bottom: solid 1px ${theme.colors.accent};
          }
        }
      }
    }
  }
`;

const Logo = styled.div`
  width: 14.4rem;
  margin: 0 auto;
  & > img {
    width: 100%;
  }
  @media (min-width: ${theme.breakpoints.desktop}) {
    margin: 0;
  }
`;

const Styled = { Nav, Mobile, Desktop, Logo, ProfileLink };
export default Styled;
