import styled from "@emotion/styled";
import theme from "../../theme";

const Profile = styled.section`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transition: left 0.8s ease-in-out;
  &[data-show="false"] {
    left: 100%;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  /* @media (min-width: ${theme.breakpoints.desktop}) {
    right: 40vw;
  } */

  @media (min-width: ${theme.breakpoints.tablet}) {
    right: 50vw;
    width: 50vw;
  }
  @media (min-width: ${theme.breakpoints.desktop}) {
    width: 60vw;
    right: 40vw;
  }
`;

const Wrapper = styled.div`
  background-color: ${theme.colors.accent};
  color: ${theme.colors.white};

  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  display: grid;
  grid-template-rows: repeat(12, 1fr);
  grid-template-columns: repeat(12, 1fr);
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: ${theme.breakpoints.tablet}) {
    left: 50vw;
    width: 50vw;
  }
  @media (min-width: ${theme.breakpoints.desktop}) {
    left: 60vw;
    width: 40vw;
  }
`;

const Form = styled.form`
display: flex;
flex-direction: column;
align-items: center;
gap: 3.2rem;

@media (min-width: ${theme.breakpoints.tablet}) {
  grid-column: 3 / span 8;
  justify-content: center;

@media (min-width: ${theme.breakpoints.desktop}) {
  padding: 48px 64px 56px;
}
`;

const Info = styled.div`
  grid-row: 3 / 6;
  grid-column: 1 / 13;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const Actions = styled.div`
  grid-row: 10 / 12;
  grid-column: 6 / 12;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: self-end;
  overflow: hidden;
`;

const PasswordModalContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 32px;
  max-width: 50%;
`;
const PasswordModalInput = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;
const PasswordModalActions = styled.div`
  display: flex;
  gap: 56px;
  & > button {
    width: 170px;
    height: 42px;
  }
`;

const CloseIconWrapper = styled.span`
  grid-column: 12;
`;
const Styled = {
  Profile,
  Overlay,
  Wrapper,
  Info,
  Form,
  Actions,
  PasswordModalContent,
  PasswordModalActions,
  PasswordModalInput,
  CloseIconWrapper,
};

export default Styled;
