import styled from "@emotion/styled";
import theme from "../../../theme";

const Sub1 = styled.h4`
  font-family: "Figtree";
  color: ${(props) => props.color || theme.colors.primary};
  font-weight: 500;
  font-size: 16px;
  line-height: 88%;
  letter-spacing: 0.05rem;
`;

const Sub2 = styled.h5`
  font-family: "Fahkwang";
  color: ${(props) => props.color || theme.colors.primary};
  font-weight: 700;
  font-size: 16px;
  line-height: 88%;
  letter-spacing: 0.05rem;
`;
const Sub3 = styled.h6`
  font-family: "Figtree";
  color: ${(props) => props.color || theme.colors.primary};
  font-weight: 400;
  font-size: 24px;
  line-height: 58%;
  letter-spacing: 0.05rem;
`;

const Sub4 = styled.h6`
  font-family: "Figtree";
  color: ${(props) => props.color || theme.colors.primary};
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.025rem;
`;

export { Sub1, Sub2, Sub3, Sub4 };
