import styled from "@emotion/styled";
import theme from "../../theme";
const Modal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(1px);
`;

const ModalContent = styled.div`
  background-color: ${theme.colors.white};
  padding: 50px 80px;
  border-radius: 4px;
`;
const Styled = { Modal, ModalContent };

export default Styled;
