import styled from "@emotion/styled";
import theme from "../../../theme";

const B1 = styled.p`
  font-family: "Figtree";
  color: ${(props) => props.color || theme.colors.primary};
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: 0.05rem;
`;

const B2 = styled.p`
  font-family: "Figtree";
  color: ${(props) => props.color || theme.colors.primary};
  font-weight: 400;
  font-size: 12px;
  line-height: 117%;
  letter-spacing: 0.05rem;
`;

export { B1, B2 };
