import { ReactNode, createContext, useState } from "react";
import { CatalogsResponse } from "../Types/API";

const AuthContext = createContext({});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [auth, setAuth] = useState({});
  const [configData, setConfigData] = useState<CatalogsResponse>({
    emailInboxes: [],
    clientes: [],
    tiposDeComprobantes: [],
    estadosComprobantes: [],
    receptores: []
  });

  return (
    <AuthContext.Provider value={{ auth, setAuth, configData, setConfigData }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
