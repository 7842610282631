import {
  AddCircleOutline,
  ChevronLeft,
  ChevronRight,
  Search,
} from "@mui/icons-material";
import TextField from "../../Components/Input/TextField";
import { Subtitle, Text } from "../../Components/Text";
import withButterfly from "../../HOC/withButterfly";
import Styled from "./style";
import theme from "../../theme";
import {
  CircularProgress,
  InputAdornment,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Button from "../../Components/Button";
import { useEffect, useState } from "react";
import Table from "../../Components/Table";
import { usersControlText } from "../../Constants/Text";
import { Link, useNavigate, useOutletContext } from "react-router-dom";

import PageHeader from "../../Components/PageHeader";
import { userType } from "../../Types/API";
import { DEFAULT_PAGE_SIZE, ROUTES } from "../../Constants";
import useAxios from "../../Hooks/useAxios";
import { apiUrls } from "../../Constants/apiUrls";
import useProtectedRoute from "../../Hooks/useProtectedRoute";
import useDebounce from "../../Hooks/useDebounce";

const Users = () => {
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState<userType[]>();
  const [pageNumber, setPageNumber] = useState(1);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();
  const breadcrumbs = [{ label: usersControlText.title, url: "/usuarios" }];

  const debounce = useDebounce();
  const { allowedRoles }: { allowedRoles: string[] } = useOutletContext();
  const protectedRoute = useProtectedRoute(allowedRoles);
  const isPrivate = true;
  const { loading, fetchData } = useAxios(isPrivate);

  const loadUsers = async () => {
    try {
      const res = await fetchData(
        `${apiUrls.users.users}?pageSize=${DEFAULT_PAGE_SIZE}&page=${pageNumber}&searchTerm=${searchValue}`
      );
      setData(res?.data?.results as userType[]);
      setTotal(res?.data?.total);
    } catch (e) {
      console.error(e);
    }
  };
  const updateUser = async ({ id, active, inboxes }: userType) => {
    const reqBody = {
      status: active ? "Inactive" : "Active",
      inboxes: inboxes.map((inbox) => inbox.emailInboxId),
    };
    try {
      await fetchData(apiUrls.users.userById(id), "PUT", reqBody);
    } catch (error) {
      console.error(error);
    }
    loadUsers();
  };
  const handleOnEdit = (id: string = "") => {
    navigate(`/${ROUTES.users}/${ROUTES.edit}/${id}`);
  };
  const goToAddUser = () => {
    navigate(`/${ROUTES.users}/${ROUTES.add}`);
  };

  const paginationPrevious = () => {
    if (pageNumber === 1) return;
    setPageNumber((page) => page - 1);
  };
  const paginationNext = () => {
    if (pageNumber * DEFAULT_PAGE_SIZE >= total) return;
    setPageNumber((page) => page + 1);
  };

  useEffect(() => {
    protectedRoute.verifyPermissions();
  }, []);
  useEffect(() => {
    loadUsers();
  }, [pageNumber, searchValue]);
  const getUserStatusAction = (user: userType) => {
    return user.active ? (
      <Styled.CellTextAction
        onClick={() => {
          updateUser(user);
        }}
      >
        <Text type="2">{usersControlText.table.action.deactivate}</Text>
      </Styled.CellTextAction>
    ) : (
      <Styled.CellTextAction
        onClick={() => {
          updateUser(user);
        }}
      >
        <Text type="2">{usersControlText.table.action.activate}</Text>
      </Styled.CellTextAction>
    );
  };

  const tableHeaders = (
    <TableRow>
      {[
        usersControlText.table.headers.name,
        usersControlText.table.headers.email,
        usersControlText.table.headers.actions,
        "",
      ].map((header) => (
        <TableCell key={header}>
          <Subtitle type="1">{header}</Subtitle>
        </TableCell>
      ))}
    </TableRow>
  );

  const tableRows = data?.map((row) => (
    <TableRow key={row.id}>
      <TableCell>
        <Link to={`${ROUTES.detail}/${row.id}`}>
          <Text type="2">{row.fullName}</Text>
        </Link>
      </TableCell>
      <TableCell>
        <Link to={`${ROUTES.detail}/${row.id}`}>
          <Text type="2">{row.email}</Text>
        </Link>
      </TableCell>
      <TableCell>
        <Styled.CellTextAction>
          <Text type="2">{getUserStatusAction(row)}</Text>
        </Styled.CellTextAction>
      </TableCell>
      <TableCell>
        <Styled.CellTextAction onClick={() => handleOnEdit(`${row.id}`)}>
          <Text type="2">{usersControlText.table.action.edit}</Text>
        </Styled.CellTextAction>
      </TableCell>
    </TableRow>
  ));
  return protectedRoute.loading ? (
    <div>Loading</div>
  ) : !protectedRoute.isAllowed ? (
    protectedRoute.renderItem
  ) : (
    <Styled.Users>
      <Styled.Content>
        <PageHeader breadcrumbs={breadcrumbs} title={usersControlText.title} />

        <Styled.Search>
          <TextField
            onChange={(e) => debounce(e.target.value, setSearchValue)}
            placeholder={usersControlText.search}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search sx={theme.iconSize} />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            startIcon={<AddCircleOutline sx={theme.iconSize} />}
            onClick={goToAddUser}
          >
            {usersControlText.addUser}
          </Button>
        </Styled.Search>
        <Styled.TableWrapper>
          <Table>
            <TableHead>{tableHeaders}</TableHead>
            {!loading && <TableBody>{tableRows}</TableBody>}
          </Table>
          {loading && (
            <Styled.Loader>
              <CircularProgress />
            </Styled.Loader>
          )}
        </Styled.TableWrapper>
        <Styled.TableActions>
          {total ? (
            <Styled.TablePagination>
              <Text type="2">
                Página {pageNumber} / {Math.ceil(total / DEFAULT_PAGE_SIZE)} de{" "}
                {total} resultados
                <span>
                  <ChevronLeft
                    sx={{ color: "#000" }}
                    onClick={paginationPrevious}
                  />
                  <ChevronRight
                    sx={{ color: "#000" }}
                    onClick={paginationNext}
                  />
                </span>
              </Text>
            </Styled.TablePagination>
          ) : (
            <></>
          )}
        </Styled.TableActions>
      </Styled.Content>
    </Styled.Users>
  );
};

export default withButterfly(Users);
