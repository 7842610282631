import Modal from "../Modal";
import { CancelOutlined, TaskAlt } from "@mui/icons-material";
import Button from "../Button";
import { Subtitle, Text } from "../Text";
import Styled from "./style";
import { ReactNode } from "react";
type ModalOptions = {
  title: string;
  subtitle?: string;
  showModal: boolean;
  onOk: () => void;
  onCancel: () => void;
  icon: ReactNode;
};

const ActionModal = (props: ModalOptions) => {
  const { showModal, onOk, onCancel, title, subtitle, icon } = props;

  const CANCEL = "No";
  const OK = "Sí";
  return (
    <Modal
      open={showModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Styled.ModalContent>
        {icon}
        <Subtitle type="2">{title}</Subtitle>
        {subtitle ? <Text type="1">{subtitle}</Text> : null}
        <Styled.ModalActions>
          <Button
            variant="outlined"
            startIcon={<CancelOutlined />}
            onClick={onCancel}
          >
            {CANCEL}
          </Button>
          <Button variant="contained" startIcon={<TaskAlt />} onClick={onOk}>
            {OK}
          </Button>
        </Styled.ModalActions>
      </Styled.ModalContent>
    </Modal>
  );
};

export default ActionModal;
