import { useState } from "react";

const useDebounce = (ms: number = 1000) => {
  const [timeoutId, setTimeoutId] = useState<any>();

  const debounceSearch = (input: any, callback: (arg1: any) => void) => {
    clearTimeout(timeoutId);
    setTimeoutId(
      setTimeout(() => {
        callback(input);
      }, ms)
    );
  };

  return debounceSearch;
};

export default useDebounce;
