import { useState } from "react";
import logo from "../../Assets/logo.svg";
import Styled from "./style";
import { MenuOutlined } from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import { Text } from "../Text";
import Profile from "../../Containers/Profile";
import { ROLES } from "../../Constants";
import { ROUTES } from "../../Constants";
import useAuth from "../../Hooks/useAuth";

const Nav = () => {
  const location = useLocation();
  const [showProfile, setShowProfile] = useState(false);
  const { auth } = useAuth();

  const getPagesByRole = (role: string | number) => {
    let pages = [];
    switch (role) {
      case ROLES.ADMIN:
        pages = [
          {
            displayName: "Control de comprobantes",
            path: `/${ROUTES.invoicing}`,
          },
          {
            displayName: "Reportes",
            path: `/${ROUTES.reports}`,
          },
          {
            displayName: "Usuarios",
            path: `/${ROUTES.users}`,
          },
        ];
        break;
      case ROLES.COLAB:
        pages = [
          {
            displayName: "Control de comprobantes",
            path: `/${ROUTES.invoicing}`,
          },
          {
            displayName: "Reportes",
            path: `/${ROUTES.reports}`,
          },
        ];
        break;
      case ROLES.PROVEEDOR:
      default:
        pages = [
          {
            displayName: "Control de comprobantes",
            path: `${ROUTES.invoicing}`,
          },
        ];
        break;
    }
    return pages;
  };

  return (
    <Styled.Nav>
      <Styled.Mobile>
        <MenuOutlined fontSize="large" onClick={() => setShowProfile(true)} />
        <Styled.Logo>
          <img src={logo} alt="" />
        </Styled.Logo>
      </Styled.Mobile>

      <Styled.Desktop>
        <Styled.Logo>
          <img src={logo} alt="" />
        </Styled.Logo>
        <ul>
          {getPagesByRole(auth?.role).map((page) => (
            <li key={page.path}>
              <Link
                data-current={location.pathname === page.path}
                to={page.path}
              >
                <Text type="1">{page.displayName}</Text>
              </Link>
            </li>
          ))}
        </ul>
        <Styled.ProfileLink onClick={() => setShowProfile(true)}>
          <Text type="1">{auth?.user}</Text>
        </Styled.ProfileLink>
      </Styled.Desktop>
      <Profile show={showProfile} handleShow={setShowProfile} />
    </Styled.Nav>
  );
};

export default Nav;
