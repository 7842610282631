import React, { ReactNode } from "react";
import { B1, B2 } from "./style";

type textProps = {
  type?: "1" | "2";
  color?: string;
  children: ReactNode;
};
const Text = ({ type = "1", color, children }: textProps) => {
  const TextComponent = type === "1" ? B1 : B2;
  return <TextComponent color={color}>{children}</TextComponent>;
};

export default Text;
