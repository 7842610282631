import styled from "@emotion/styled";
import theme from "../../theme";

const AlphaTransparency = "66";
const LoginWrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 1fr;
  background-color: ${theme.colors.white}${AlphaTransparency};
  height: 100vh;
  & > img {
    display: none;
  }
  @media (min-width: ${theme.breakpoints.tablet}) {
    & > img {
      display: block;
      position: fixed;
      right: -16%;
      bottom: -10vh;
      top: 0;
      width: 70%;
      height: 120vh;
    }
  }
`;

const IconClickable = styled.span`
  cursor: pointer;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: span 12;
  grid-row: span 1;
  box-shadow: 6px 4px 60px rgba(81, 93, 86, 0.3);
  height: 100%;

  background-color: ${theme.colors.white};
  z-index: 1;
  justify-content: center;
  padding: 0 5%;

  @media (min-width: ${theme.breakpoints.tablet}) {
    grid-column: span 7;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  & > img {
    width: 60%;
    padding-bottom: 0.8rem;
  }
  @media (min-width: ${theme.breakpoints.tablet}) {
    grid-column: 3 / span 8;
    justify-content: center;
    & > img {
      grid-column: 3 / span 5;
      padding-bottom: 4em;
    }
  }
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;
const Styled = {
  LoginWrapper,
  FormWrapper,
  Form,
  ButtonWrapper,
  CheckboxWrapper,
  InputWrapper,
  IconClickable,
};
export default Styled;
