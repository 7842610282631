import React, { ReactNode } from "react";
import { Sub1, Sub2, Sub3, Sub4 } from "./style";

type subtitleProps = {
  type?: "1" | "2" | "3" | "4";
  color?: string;
  children: ReactNode;
};
const Subtitle = ({ type = "1", color, children }: subtitleProps) => {
  let SubitleComponent;
  switch (type) {
    case "1":
      SubitleComponent = Sub1;
      break;
    case "2":
      SubitleComponent = Sub2;
      break;
    case "3":
      SubitleComponent = Sub3;
      break;
    case "4":
      SubitleComponent = Sub4;
      break;
    default:
      SubitleComponent = Sub3;
      break;
  }

  return <SubitleComponent color={color}>{children}</SubitleComponent>;
};

export default Subtitle;
