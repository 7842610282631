import React, { ReactNode } from "react";
import { T1, T2, T3 } from "./style";

type titleProps = {
  type?: "1" | "2" | "3";
  color?: string;
  children: ReactNode;
};
const Title = ({ type = "1", color, children }: titleProps) => {
  const TitleComponent = type === "1" ? T1 : type === "2" ? T2 : T3;
  return <TitleComponent color={color}>{children}</TitleComponent>;
};

export default Title;
