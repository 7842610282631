import styled from "@emotion/styled";
import theme from "../../../theme";

const T1 = styled.h1`
  font-family: "Fahkwang";
  color: ${(props) => props.color || theme.colors.primary};
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 44%;
  letter-spacing: 0.05rem;
`;

const T2 = styled.h2`
  font-family: "Figtree";
  color: ${(props) => props.color || theme.colors.primary};
  font-weight: 700;
  font-size: 24px;
  line-height: 58%;
  letter-spacing: 0.5px;
`;
const T3 = styled.h2`
  font-family: "Figtree";
  color: ${(props) => props.color || theme.colors.primary};
  font-weight: 700;
  font-size: 24px;
  line-height: 14px;
  letter-spacing: 0.5px;
`;

export { T1, T2, T3 };
