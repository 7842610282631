import styled from "@emotion/styled";
import theme from "../../theme";

const InvoiceDetail = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
`;

const ContentDetail = styled.div`
  padding-top: 50px;
  grid-column: 2 / 12;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  padding: 45px 0;
`;
const Status = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 80px;
  padding-top: 45px;
  flex-wrap: wrap;
  & span {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    flex-wrap: nowrap;
  }
`;
const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding-top: 45px;
  & > h2 {
    border-bottom: 1px solid ${theme.colors.accent};
    line-height: 1;
    white-space: nowrap;
    width: min-content;
  }
  & > hr {
    border-top: ${theme.colors.disabledLight};
  }
`;

const DataWrapperContent = styled.div`
  display: flex;
  justify-content: flex-start;
  row-gap: 4rem;
  column-gap: 1rem;
  align-items: center;
  gap: 60px;
  flex-wrap: wrap;
  h6 {
    line-height: 1;
  }
`;

const DataWrapperItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 25ch;
  gap: 20px;
`;

const InvoiceActions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 45px 0 90px;
`;
const AdditionalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
  padding: 0 0 45px;
`;
const StatusActions = styled.div`
  border-top: 1px solid ${theme.colors.disabledLight};
  padding: 45px 0 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
`;
const TableWrapper = styled.div`
  max-height: 40vh;
  overflow: scroll;
  position: relative;
`;
const TitleUnderlined = styled.div`
  padding-bottom: 4rem;
  & > h2 {
    border-bottom: 1px solid ${theme.colors.accent};
    line-height: 1;
    white-space: nowrap;
    width: min-content;
  }
`;

const LineEditWrapper = styled.div``;
const LineEditHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 0 32px 0;
  border-bottom: 1px solid ${theme.colors.disabledLight};
  h1 {
    margin: 0 auto;
  }
`;
const LineEditContent = styled.div``;
const LineEditDetail = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px 0;
  span {
    display: flex;
    flex-direction: row;
    gap: 30px;
  }
`;
const LineEditForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 60px;
`;
const LineEditFormFields = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  h6 {
    line-height: 1;
  }
`;
const LineEditItem = styled.div`
  width: 325px;
`;
const LineEditActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
`;
const ErrorIcon = styled.div`
  border: solid 1px ${theme.colors.alert};
  background-color: ${theme.colors.white};
  border: solid 5px red;
  background-color: #ffffff;
  border-radius: 50%;
  width: 66px;
  height: 66px;
`;
const Styled = {
  InvoiceDetail,
  ContentDetail,
  Title,
  Status,
  DataWrapper,
  DataWrapperContent,
  DataWrapperItem,
  InvoiceActions,
  AdditionalActions,
  StatusActions,
  TableWrapper,
  TitleUnderlined,
  LineEditWrapper,
  LineEditHeader,
  LineEditContent,
  LineEditActions,
  LineEditDetail,
  LineEditForm,
  LineEditFormFields,
  LineEditItem,
  ErrorIcon,
};

export default Styled;
