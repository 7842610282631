import styled from "@emotion/styled";

const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 32px;
`;
const ModalActions = styled.div`
  display: flex;
  gap: 56px;
  & > button {
    width: 170px;
    height: 42px;
  }
`;

const Styled = { ModalContent, ModalActions };

export default Styled;
