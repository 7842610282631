import React, { forwardRef } from "react";
import { TextFieldProps, TextField as MuiTextField } from "@mui/material";
import { customTheme, inputBaseStyle } from "./style";
type TextOptions = {} & TextFieldProps;
const TextField = (props: TextOptions, ref: any) => {
  const { id, label, variant = "outlined", children } = props;
  const fieldTheme = {
    outlined: { theme: customTheme.outlined },
    filled: { theme: customTheme.filled },
    standard: { theme: customTheme.standard },
  };
  const style = fieldTheme[variant].theme;
  return (
    <MuiTextField
      id={id}
      label={label}
      variant={variant}
      sx={style}
      inputProps={inputBaseStyle}
      {...props}
      ref={ref}
    >
      {children}
    </MuiTextField>
  );
};
export default forwardRef(TextField);
