import Style from "./style";
const Loading = () => {
  return (
    <Style.LoadingWrapper>
      <Style.Spinner />
    </Style.LoadingWrapper>
  );
};

export default Loading;
