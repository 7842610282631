import { Breadcrumbs as BreadcrumbsMui } from "@mui/material";
import { Link } from "react-router-dom";
import { Subtitle, Text } from "../Text";
import Styled from "./style";

export interface BreadcrumbItem {
  label: string;
  url?: string;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

const Breadcrumbs = ({ items }: BreadcrumbProps) => {
  const typography = (text: string, isLast: boolean) => {
    return isLast ? (
      <Subtitle type="1">{text}</Subtitle>
    ) : (
      <Text type="1">{text}</Text>
    );
  };
  const links = items.map((path, index) => (
    <Styled.LinkWrapper key={index}>
      <Link to={path.url || "/"}>
        {typography(path.label, index === items.length - 1)}
      </Link>
    </Styled.LinkWrapper>
  ));

  return <BreadcrumbsMui>{links}</BreadcrumbsMui>;
};

export default Breadcrumbs;
