import React, { ComponentType } from "react";
import butterfly from "../../Assets/butterfly.svg";
import Styled from "./style";

const withButterfly = <P extends object>(
  WrappedComponent: ComponentType<P>
): React.FC<P> => {
  const WithBackgroundImage: React.FC<P> = ({ ...props }) => {
    return (
      <Styled.Background>
        <Styled.Butterfly src={butterfly} />
        <Styled.WrappedComponent>
          <WrappedComponent {...(props as P)} />
        </Styled.WrappedComponent>
      </Styled.Background>
    );
  };

  return WithBackgroundImage;
};

export default withButterfly;
