import styled from "@emotion/styled";
import theme from "../../theme";

const UserAdd = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
`;
const Content = styled.section`
  padding-top: 50px;
  grid-column: 2 / 12;
  display: flex;
  flex-direction: column;
`;

const Form = styled.form``;
const UserInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  row-gap: 5.5rem;
  column-gap: 1rem;
  padding-top: 6.4rem;
`;
const UserInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: span 12;
  gap: 2rem;
  .MuiInputAdornment-root svg:hover {
    cursor: pointer;
  }
  @media (min-width: ${theme.breakpoints.tablet}) {
    grid-column: span 6;
  }
  @media (min-width: ${theme.breakpoints.desktop}) {
    grid-column: span 4;
  }
`;

const EditWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  padding-top: 8.3rem;
`;

const Styled = {
  UserAdd,
  Content,
  Form,
  UserInfo,
  UserInfoItem,
  EditWrapper,
};

export default Styled;
