const colors = {
  primary: "#515D56",
  secondary: "#709481",
  accent: "#BDC205",
  alert: "#DB2D10",
  disabledLight: "#E4E0DB",
  disabledDark: "#939598",
  white: "#FFFFFF",
};

export default colors;
