import theme from "../../../theme";

const customTheme = {
  outlined: {
    borderColor: "rgba(0,0,0,0.42)",
    light: "rgba(0,0,0,0.42)",
    color: theme.colors.disabledDark,
    ":hover": {
      borderColor: "rgba(0,0,0,0.42)",
    },
    "& label.Mui-focused": {
      color: theme.colors.disabledDark,
    },
    "& p.MuiFormHelperText-root": {
      fontSize: "1.6rem",
      textAlign: "end",
    },
    // focused color for input with variant='standard'
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.colors.disabledDark,
    },
    // focused color for input with variant='filled'
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: theme.colors.disabledDark,
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: theme.colors.disabledDark,
      },
    },
  },
  filled: {
    borderColor: "rgba(0,0,0,0.42)",
    light: "rgba(0,0,0,0.42)",
    color: theme.colors.disabledDark,
    ":hover": {
      borderColor: "rgba(0,0,0,0.42)",
    },
    "& label.Mui-focused": {
      color: theme.colors.disabledDark,
    },
    // focused color for input with variant='standard'
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.colors.disabledDark,
    },
    // focused color for input with variant='filled'
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: theme.colors.disabledDark,
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: theme.colors.disabledDark,
      },
    },
  },
  standard: {
    borderColor: "rgba(0,0,0,0.42)",
    light: "rgba(0,0,0,0.42)",
    color: theme.colors.disabledDark,
    ":hover": {
      borderColor: "rgba(0,0,0,0.42)",
    },
    "& label.Mui-focused": {
      color: theme.colors.disabledDark,
    },
    // focused color for input with variant='standard'
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.colors.disabledDark,
    },
    // focused color for input with variant='filled'
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: theme.colors.disabledDark,
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: theme.colors.disabledDark,
      },
    },
  },
};
const inputBaseStyle = { style: { fontSize: "1.6rem" } };

export { customTheme, inputBaseStyle };
