import { CancelOutlined } from "@mui/icons-material";
import { Title } from "../../Components/Text";
const NotFound = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        padding: "10%",
      }}
    >
      <CancelOutlined />
      <Title type="1">404</Title>
      <Title type="2">Not Found</Title>
    </div>
  );
};

export default NotFound;
