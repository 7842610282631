import styled from "@emotion/styled";

const Users = styled.section`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
`;
const Content = styled.section`
  padding-top: 50px;
  grid-column: 2 / 12;
  display: flex;
  flex-direction: column;
`;
const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  padding: 45px 0;
`;

const CellTextAction = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

const Search = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 0 64px;
  svg {
    cursor: pointer;
  }
`;

const TableWrapper = styled.div`
  tr td a {
    text-decoration: none;
  }
`;
const TableActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 64px 0 80px;
`;
const TablePagination = styled.div`
  p {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    & span {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 24px;
    }
  }
`;
const Styled = {
  Users,
  Content,
  Loader,
  Title,
  Search,
  TableWrapper,
  TableActions,
  TablePagination,
  CellTextAction,
};

export default Styled;
