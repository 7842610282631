import { Routes, Route } from "react-router-dom";

import Login from "../Containers/Login";
import InvoicingControl from "../Containers/InvoicingControl";
import NotFound from "../Containers/404";
import Unauthorized from "../Containers/Unauthorized";
import MainLayout from "../Layout/MainLayout";
import InvoiceDetail from "../Containers/InvoiceDetail";
import Reports from "../Containers/Reports";
import { ROUTES } from "../Constants";
import Layout from "../Layout/Layout";
import { ROLES } from "../Constants";
import Users from "../Containers/Users";
import UserAdd from "../Containers/UserAdd";
import UserDetail from "../Containers/UserDetail";
import History from "../Containers/Reports/History";
import { useLocalStorage } from "../Hooks/useLocalStorage";
import { useEffect } from "react";
import useAuth from "../Hooks/useAuth";

const Routing = () => {
  const [storedValue, _] = useLocalStorage("auth", {});
  const { setAuth } = useAuth();
  useEffect(() => {
    if (Object.keys(storedValue).length > 0) {
      setAuth(storedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<Layout />}>
        <Route index element={<Login />} />

        {/* Protected routes */}

        <Route
          element={
            <MainLayout
              allowedRoles={[ROLES.ADMIN, ROLES.COLAB, ROLES.PROVEEDOR]}
            />
          }
        >
          <Route path={`/${ROUTES.invoicing}`} element={<InvoicingControl />} />
          <Route
            path={`/${ROUTES.invoicing}/:id`}
            element={<InvoiceDetail />}
          />
        </Route>

        <Route
          element={<MainLayout allowedRoles={[ROLES.ADMIN, ROLES.COLAB]} />}
        >
          <Route path={`/${ROUTES.reports}`} element={<Reports />} />

          <Route
            path={`/${ROUTES.reports}/${ROUTES.reportHistory}`}
            element={<History />}
          />
        </Route>
        <Route element={<MainLayout allowedRoles={[ROLES.ADMIN]} />}>
          <Route path={`/${ROUTES.users}`} element={<Users />} />
          <Route
            path={`/${ROUTES.users}/${ROUTES.add}`}
            element={<UserAdd />}
          />

          <Route
            path={`/${ROUTES.users}/${ROUTES.edit}/:userId`}
            element={<UserAdd />}
          />
          <Route
            path={`/${ROUTES.users}/${ROUTES.detail}/:userId`}
            element={<UserDetail />}
          />
        </Route>

        {/* Catch all */}

        <Route path={`/${ROUTES.unauthorized}`} element={<Unauthorized />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default Routing;
