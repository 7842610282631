import styled from "@emotion/styled";
import theme from "../../theme";

const Invoicing = styled.section`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
`;
const Content = styled.section`
  padding-top: 50px;
  grid-column: 2 / 12;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  padding: 45px 0;
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Filters = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px;
  padding: 31px 0;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    flex-wrap: wrap;
    gap: 24px;

    .MuiInputBase-input {
      width: 266px;
      height: 56px;
      box-sizing: border-box;
    }
    .MuiButtonBase-root {
      width: 169px;
      height: 42px;
    }
    .MuiNativeSelect-select {
      color: ${theme.colors.primary};

      font-size: 1.6rem;
      line-height: 1;
    }
  }
`;

const TableActions = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 64px;
  padding-bottom: 80px;
  gap: 25px;
  & > div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 25px;
  }
`;

const TablePagination = styled.div`
  p {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    & span {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 24px;
    }
  }
  svg:hover {
    cursor: pointer;
  }
`;
const LineEditItem = styled.div`
  width: 260px;
  .MuiNativeSelect-select {
    height: 56px;
    box-sizing: border-box;
  }
  h6 {
    line-height: 1;
  }
`;

const FilterModal = styled.div``;

const FilterModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  padding-bottom: 32px;
  border-bottom: 1px solid ${theme.colors.disabledLight};
  h1 {
    margin: 0 auto;
  }
`;

const FilterModalForm = styled.form`
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr;
  padding: 64px 0 80px 0;
  @media (min-width: ${theme.breakpoints.tablet}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: ${theme.breakpoints.desktop}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const FilterModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledPopover = styled.div`
  display: flex;
  flex-direction: column;
  background: #bdc205;
  color: #fff;
  padding: 8px 0;
  & > div {
    padding: 16px 14px;
    &:hover {
      background: rgba(81, 93, 86, 0.1);
      cursor: pointer;
    }
  }
`;

const RowActions = styled.div`
  > span {
    letter-spacing: 1px;
    font-size: 1.5rem;
    &:hover {
      cursor: pointer;
    }
  }
`;

const SelectRowsActions = styled.div`
  display: flex;
  gap: 0.5rem;
  padding-bottom: 31px;
`;
const CellScroll = styled.div`
  overflow: auto;
`;

const Styled = {
  Invoicing,
  Content,
  Title,
  Filters,
  TableActions,
  TablePagination,
  FilterModal,
  FilterModalHeader,
  FilterModalForm,
  FilterModalFooter,
  LineEditItem,
  Loader,
  StyledPopover,
  RowActions,
  SelectRowsActions,
  CellScroll,
};
export default Styled;
