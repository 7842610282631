import { useEffect, useState } from "react";
import useAuth from "../../Hooks/useAuth";
import { useNavigate, Navigate } from "react-router-dom";
import Styled from "./style";
import butterfly from "../../Assets/butterfly.svg";
import logo from "../../Assets/logo.svg";
import TextField from "../../Components/Input/TextField";
import Button from "../../Components/Button";
import {
  ArrowForward,
  CheckBoxOutlineBlankOutlined,
  CheckBoxOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { Subtitle, Text } from "../../Components/Text";
import { useForm } from "react-hook-form";
import { loginText } from "../../Constants/Text";
import { useLocalStorage } from "../../Hooks/useLocalStorage";
import { ROUTES } from "../../Constants";
import useAxios from "../../Hooks/useAxios";
import { authResponse, CatalogsResponse } from "../../Types/API";
import { apiUrls } from "../../Constants/apiUrls";
import { InputAdornment } from "@mui/material";
import theme from "../../theme";


const Login = () => {
  const { auth, setAuth, setConfigData } = useAuth();

  const navigate = useNavigate();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [storedValue, setValue] = useLocalStorage("auth", {});
  const [storedEmail, setEmailStorage] = useLocalStorage("email", "");
  const [rememberMe, setRememberMe] = useState(!!storedEmail);
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: { email: storedEmail, password: "" },
  });
  const { fetchData } = useAxios();
  const axiosPrivate = useAxios(true);

  const onSubmit = async (data: any) => {
    try {
      const response = (await fetchData(apiUrls.auth.authenticate, "POST", {
        email: data.email,
        password: data.password,
      })) as { data: authResponse };

      const auth = {
        user: response.data.user.email,
        role: response.data.user.role,
        fullName: response.data.user.fullName,
        accessToken: response.data.IdToken.jwtToken,
        refreshToken: response.data.RefreshToken.token,
        id: response.data.user.id,
      };
      setAuth(auth);
      setValue(auth);
      reset();
      navigate(`/${ROUTES.invoicing}`, { replace: true });
      if (rememberMe) {
        setEmailStorage(data.email);
      } else {
        setEmailStorage("");
      }
    } catch (error) {
      setError("password", { type: "custom" });
    }
  };
  const getConfig = async () => {
    try {
      const response = (await axiosPrivate.fetchData(
        apiUrls.catalog.catalogs,
        "GET"
      )) as {
        data: CatalogsResponse;
      };
      setConfigData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (Object.keys(storedValue).length > 0) {
      setAuth(storedValue);
      setIsLoggedIn(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (auth && Object.keys(auth).length > 0) {
      getConfig();
    }
  });
  return isLoggedIn ? (
    <Navigate to={`/${ROUTES.invoicing}`} />
  ) : (
    <Styled.LoginWrapper>
      <Styled.FormWrapper>
        <Styled.Form onSubmit={handleSubmit(onSubmit)}>
          <img src={logo} alt="Peninsula Papagayo logo" />
          <Styled.InputWrapper>
            <label htmlFor="username">
              <Subtitle type="2">{loginText.input.email.label}</Subtitle>
            </label>
            <TextField
              id="username"
              error={!!errors.email}
              helperText={errors.email && loginText.input.email.errorHelper}
              placeholder={loginText.input.email.label}
              autoComplete="on"
              required
              {...register("email", {
                required: "",
                pattern: {
                  value: /^\w+([.-]?\w+\+*)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                  message: "error message",
                },
              })}
            />
          </Styled.InputWrapper>

          <Styled.InputWrapper>
            <label htmlFor="password">
              <Subtitle type="2">{loginText.input.password.label}</Subtitle>
            </label>
            <TextField
              type={showPassword ? "text" : "password"}
              id="password"
              placeholder={loginText.input.password.label}
              error={!!errors.password}
              helperText={
                errors.password ? loginText.input.password.errorHelper : null
              }
              autoComplete="off"
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={() => setShowPassword((show) => !show)}
                  >
                    {showPassword ? (
                      <Styled.IconClickable>
                        <VisibilityOff style={theme.iconSize} />
                      </Styled.IconClickable>
                    ) : (
                      <Styled.IconClickable>
                        <Visibility style={theme.iconSize} />
                      </Styled.IconClickable>
                    )}
                  </InputAdornment>
                ),
              }}
              {...register("password", { required: "" })}
            />
          </Styled.InputWrapper>
          <Styled.CheckboxWrapper>
            {rememberMe ? (
              <CheckBoxOutlined
                onClick={() => setRememberMe(false)}
                style={{ width: "24px", height: "24px" }}
              />
            ) : (
              <CheckBoxOutlineBlankOutlined
                onClick={() => setRememberMe(true)}
                style={{ width: "24px", height: "24px" }}
              />
            )}
            <span>
              <Text type="1">{loginText.input.remember_me}</Text>
            </span>
          </Styled.CheckboxWrapper>
          <Styled.ButtonWrapper>
            <Button
              type="submit"
              startIcon={<ArrowForward />}
              disabled={!isValid}
            >
              {loginText.login}
            </Button>
          </Styled.ButtonWrapper>
        </Styled.Form>
      </Styled.FormWrapper>
      <img src={butterfly} alt="" />
    </Styled.LoginWrapper>
  );
};

export default Login;
