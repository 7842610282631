import { css } from "@emotion/react";

export const TableStyles = css`
  &.MuiTableContainer-root {
    .MuiTable-root {
      .MuiTableHead-root {
        .MuiTableRow-root {
          .MuiTableCell-root {
            padding-bottom: 32px;
            border-bottom: none;
          }
        }
      }
      .MuiTableBody-root {
        .MuiTableRow-root {
          .MuiTableCell-root {
            max-width: 20ch;
          }
        }
      }
    }
  }
`;
