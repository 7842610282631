import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import Styled from "./style";
import PageHeader from "../../Components/PageHeader";
import { usersControlText } from "../../Constants/Text";
import { Subtitle, Title } from "../../Components/Text";
import { Edit } from "@mui/icons-material";
import { CatalogsResponse, userType } from "../../Types/API";
import Button from "../../Components/Button";
import theme from "../../theme";
import { ROUTES } from "../../Constants";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import useAxios from "../../Hooks/useAxios";
import { apiUrls } from "../../Constants/apiUrls";
import useAuth from "../../Hooks/useAuth";
import useProtectedRoute from "../../Hooks/useProtectedRoute";

const UserDetail = () => {
  const { userId = "" } = useParams();
  const navigate = useNavigate();

  const { allowedRoles }: { allowedRoles: string[] } = useOutletContext();
  const protectedRoute = useProtectedRoute(allowedRoles);

  const { configData, setConfigData } = useAuth();
  const [userData, setUserData] = useState<userType>();
  const { fetchData, loading } = useAxios(true);

  const loadUserDetails = async () => {
    try {
      const res = await fetchData(apiUrls.users.userById(Number(userId)));
      setUserData(res?.data as userType);
    } catch (e) {
      console.error(e);
    }
  };
  const breadcrumbs = [
    { label: usersControlText.title, url: "/usuarios" },
    { label: usersControlText.detail.detail, url: "" },
  ];
  const handleOnEdit = (id: string) => {
    navigate(`/${ROUTES.users}/${ROUTES.edit}/${id}`);
  };

  const getConfig = async () => {
    try {
      const response = (await fetchData(apiUrls.catalog.catalogs, "GET")) as {
        data: CatalogsResponse;
      };
      setConfigData(response?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    protectedRoute.verifyPermissions();
  }, []);
  useEffect(() => {
    if (!protectedRoute.loading && protectedRoute.isAllowed && !userData) {
      loadUserDetails();
      // if (userData && accounts.length !== userData?.inboxes.length) {
      getConfig();
      // }
    }
  }, [protectedRoute.loading, protectedRoute.isAllowed, userData]);

  const loadConfig = async () => {
    if (!configData) await getConfig();
  };
  useEffect(() => {
    loadConfig();
  }, [configData]);

  return (
    <Styled.UserDetail>
      <Styled.Content>
        <PageHeader
          breadcrumbs={breadcrumbs}
          title={usersControlText.detail.title}
        />
        {loading ? (
          <Styled.Loader>
            <CircularProgress />
          </Styled.Loader>
        ) : (
          <Styled.UserInfo>
            <Styled.UserInfoItem>
              <Title type="3">{usersControlText.detail.info.name}</Title>
              <Subtitle type="3">{userData?.fullName}</Subtitle>
            </Styled.UserInfoItem>
            <Styled.UserInfoItem>
              <Title type="3">{usersControlText.detail.info.email}</Title>
              <Subtitle type="3">{userData?.email}</Subtitle>
            </Styled.UserInfoItem>
            <Styled.UserInfoItem>
              <Title type="3">{usersControlText.detail.info.password}</Title>
              <Subtitle type="3">********</Subtitle>
            </Styled.UserInfoItem>
            <Styled.UserInfoItem>
              <Title type="3">{usersControlText.detail.info.accounts}</Title>
              {userData?.inboxes.map((cuenta) => (
                <Subtitle type="3" key={cuenta.emailInboxId}>
                  {
                    configData?.emailInboxes.find(
                      (e: { id: number }) => e.id === +cuenta?.emailInboxId
                    )?.email
                  }
                </Subtitle>
              ))}
            </Styled.UserInfoItem>
            <Styled.UserInfoItem>
              <Title type="3">{usersControlText.detail.info.role}</Title>
              <Subtitle type="3">{userData?.role}</Subtitle>
            </Styled.UserInfoItem>
            <Styled.UserInfoItem>
              <Title type="3">{usersControlText.detail.info.status}</Title>
              <Subtitle type="3">
                {userData?.active ? "Activo" : "Inactivo"}
              </Subtitle>
            </Styled.UserInfoItem>
          </Styled.UserInfo>
        )}
        <Styled.EditWrapper>
          <Button
            variant="contained"
            startIcon={<Edit sx={theme.iconSize} />}
            onClick={() => handleOnEdit(userId)}
          >
            {usersControlText.edit.edit}
          </Button>
        </Styled.EditWrapper>
      </Styled.Content>
    </Styled.UserDetail>
  );
};

export default UserDetail;
