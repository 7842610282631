import Breadcrumbs, { BreadcrumbItem } from "../Breadcrumbs";
import HR from "../Divider";
import { Title } from "../Text";
import Styled from "./style";

type PageHeaderOptions = {
  title: string;
  breadcrumbs: BreadcrumbItem[];
};

const PageHeader = ({ title, breadcrumbs }: PageHeaderOptions) => {
  return (
    <>
      <Breadcrumbs items={breadcrumbs} />
      <Styled.Title>
        <Title type="1">{title}</Title>
      </Styled.Title>
      <HR />
    </>
  );
};

export default PageHeader;
