import React, { useEffect, useRef, useState } from "react";
import { Subtitle, Text, Title } from "../../Components/Text";
import Styled from "./style";
import withButterfly from "../../HOC/withButterfly";
import {
  CircularProgress,
  InputAdornment,
  Popover,
  Select,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Utils, { formatDateFromMs } from "../../Utils";
import Table from "../../Components/Table";
import Button from "../../Components/Button";
import {
  CancelOutlined,
  ChevronLeft,
  ChevronRight,
  Edit,
  OpenInNew,
  Search,
  Tune,
} from "@mui/icons-material";
import TextField from "../../Components/Input/TextField";
import theme from "../../theme";
import { Link, useOutletContext, useSearchParams } from "react-router-dom";
import Modal from "../../Components/Modal";
import { useForm } from "react-hook-form";
import { invocingControlText, invoiceStatus } from "../../Constants/Text";
import PageHeader from "../../Components/PageHeader";
import {
  CatalogsResponse,
  invoiceListResponse,
  InvoiceType, TextValue,
} from "../../Types/API";
import useAxios from "../../Hooks/useAxios";
import { apiUrls } from "../../Constants/apiUrls";
import { DEFAULT_PAGE_SIZE, MIME_TYPE } from "../../Constants";
import useAuth from "../../Hooks/useAuth";
import useProtectedRoute from "../../Hooks/useProtectedRoute";
import useDebounce from "../../Hooks/useDebounce";

const Invoicing = () => {
  const { allowedRoles }: { allowedRoles: string[] } = useOutletContext();
  const [invoicesData, setInvoicesData] = useState<InvoiceType[]>();
  const [configRetries, setConfigRetries] = useState(0);
  const [total, setTotal] = useState(0);
  const [queryParams, setQueryParams] = useSearchParams();
  const [clave, setClave] = useState(queryParams.get("clave"));
  const claveRef = useRef<HTMLInputElement>();
  const [selectedLine, setSelectedLine] = useState(0);
  const isPrivate = true;
  const { loading, fetchData } = useAxios(isPrivate);
  const axiosUpdate = useAxios(isPrivate);
  const protectedRoute = useProtectedRoute(allowedRoles);
  const {
    configData,
    setConfigData,
  }: { configData: CatalogsResponse; setConfigData: (arg0: any) => void } =
    useAuth();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const debounce = useDebounce();

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedLine(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const loadInvoices = async () => {
    try {
      const reqUrl = `${
        apiUrls.invoices.getInvoices
      }?pageSize=${DEFAULT_PAGE_SIZE}&inboxes=${inboxFilter}${Utils.createParams(
        getQueryParamsObject()
      )}`;
      const res = (await fetchData(reqUrl)) as { data: invoiceListResponse };
      setInvoicesData(res.data.results);
      setTotal(res.data.total);
    } catch (e) {
      setInvoicesData([]);
      setTotal(0);
    }
  };

  const getConfig = async () => {
    try {
      const response = (await fetchData(apiUrls.catalog.catalogs, "GET")) as {
        data: CatalogsResponse;
      };
      if (!response?.data) {
        if (configRetries < 3) {
          getConfig();
          setConfigRetries((retries) => retries + 1);
        }
      } else {
        setConfigRetries(0);
        setConfigData(response.data);
        const inboxQueryNumber = Number(queryParams.get("cuenta"));
        if (!inboxQueryNumber || isNaN(inboxQueryNumber)) {
          const inbox = response.data.emailInboxes[0].id;
          setQueryParams({
            ...getQueryParamsObject(),
            cuenta: `${inbox}`,
          });
          setInboxFilter(inbox);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateStatus = async (status: string) => {
    try {
      handleClose();
      await axiosUpdate.fetchData(
        apiUrls.invoices.getInvoicesById(selectedLine),
        "PATCH",
        {
          status,
        }
      );
      if (invoicesData) {
        const index = invoicesData.findIndex((e) => +e?.id === selectedLine);

        setInvoicesData((prev) => {
          if (prev) {
            prev[index].status = status;
          }
          return prev;
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateStatusBulk = async (status: string) => {
    try {
      await Promise.all(
        selectedRows.map((id) =>
          fetchData(apiUrls.invoices.getInvoicesById(id), "PATCH", {
            status,
          })
        )
      );
      await loadInvoices();
    } catch (e) {
      console.error(e);
    }
  };

  const filterFormDefaultData = {
    tipo: "",
    startDate: "",
    endDate: "",
    estado: "",
    cliente: "",
    receptor: "",
  };
  const [breadcrumbs, setBreadcrumbs] = useState([
    { label: invocingControlText.title, url: "/" },
  ]);
  const [inboxFilter, setInboxFilter] = useState(
    Number(queryParams.get("cuenta"))
  );
  const [showFilterModal, setShowFilterModal] = useState(false);
  const filterForm = useForm({
    defaultValues: filterFormDefaultData,
  });

  const [selectRows, setSelectRows] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const generateReport = async (isDetailed = false) => {
    const formData = filterForm.getValues();
    const requestBody = {
      ...formData,
      startDate: formData.startDate
        ? new Date(formData.startDate).toISOString()
        : "",
      endDate: formData.endDate ? new Date(formData.endDate).toISOString() : "",
      clave,
      inboxes: [inboxFilter],
      receptor: formData.receptor,
    };
    const url = isDetailed
      ? apiUrls.reports.generateReportDetailed
      : apiUrls.reports.generateReportRegular;
    const createReportResponse = (await fetchData(
      `${url}`,
      "POST",
      Utils.removeFalsyValuesFromObject(requestBody)
    )) as { data: { message: string; id: number } };
    if (!createReportResponse || !createReportResponse.data.id) {
      // TODO: Create message showing error
      return;
    }
    try {
      const res = await fetchData(
        apiUrls.reports.downloadReport(createReportResponse.data.id),
        "GET",
        {},
        { responseType: "arraybuffer" }
      );

      const filename = res?.headers["content-disposition"].split("=")[1];
      Utils.downloadFile(res?.data, filename, MIME_TYPE.excel);
    } catch (e) {
      console.error(e);
    }
    return createReportResponse;
  };
  const handleFilterSearch = async () => {
    const formData = filterForm.getValues();
    const startDate = formData.startDate
      ? new Date(formData.startDate).toISOString()
      : "";
    const endDate = formData.endDate
      ? new Date(formData.endDate).toISOString()
      : "";

    setQueryParams(
      Utils.removeFalsyValuesFromObject({
        ...getQueryParamsObject(),
        tipo: formData?.tipo,
        inicio: startDate.split("T")[0],
        fin: endDate.split("T")[0],
        estado: formData.estado,
        cliente: formData.cliente,
        page: "1",
        receptor: formData.receptor
      })
    );
    setShowFilterModal(false);
  };
  const handleClearFilters = () => {
    const newParams = {
      page: "1",
      cuenta: `${inboxFilter}`,
    };
    setQueryParams(newParams);
    filterForm.reset();
    setShowFilterModal(false);
  };

  const paginationPrevious = () => {
    const pageNumber = getPageNumber();
    if (!pageNumber || pageNumber === 1) return;
    setQueryParams({ ...getQueryParamsObject(), page: `${pageNumber - 1}` });
  };
  const paginationNext = () => {
    const pageNumber = getPageNumber();

    if (!pageNumber || pageNumber * DEFAULT_PAGE_SIZE >= total) return;
    setQueryParams({ ...getQueryParamsObject(), page: `${pageNumber + 1}` });
  };




  const debounceSearch = (input: any) => {
    const setNewClave = (input: string) => {
      setClave(input);
      setQueryParams({ ...getQueryParamsObject(), clave: input });
    };
    debounce(input, setNewClave);
  };
  const tableWidths = (
    <colgroup>
      <col style={{ width: "6%" }} /> {/* Fecha */}
      <col style={{ width: "12%" }} /> {/* Tipo */}
      <col style={{ width: "15%" }} /> {/* Clave */}
      <col style={{ width: "20%" }} /> {/* Proveedor */}
      <col style={{ width: "6%", textAlign: "center" }} /> {/* Moneda */}
      <col style={{ width: "10%" }} /> {/* Total */}
      <col style={{ width: "10%" }} /> {/* Total Impuesto */}
      <col style={{ width: "17%" }} /> {/* Receptor */}
      <col style={{ width: "8%" }} /> {/* Status */}
      <col style={{ width: "2%" }} /> {/* Opciones */}
    </colgroup>
  );

  const tableHeaders = (
    <TableRow>
      {selectRows ? <TableCell></TableCell> : null}
      {(
        [
          { text: invocingControlText.table.headers.date },
          { text: invocingControlText.table.headers.type },
          { text: invocingControlText.table.headers.id },
          { text: invocingControlText.table.headers.provider },
          { text: invocingControlText.table.headers.currency },
          {
            text: invocingControlText.table.headers.total,
            align: "right" as const,
          },
          {
            text: invocingControlText.table.headers.totalImpuesto,
            align: "right" as const,
          },
          { text: invocingControlText.table.headers.receptor },
          { text: invocingControlText.table.headers.status },
          { text: "" },
        ] satisfies { text: string; align?: string }[]
      ).map((header, index) => (
        <TableCell key={index} style={{ textAlign: header.align || "left" }}>
          <Subtitle type="1">{header.text}</Subtitle>
        </TableCell>
      ))}
    </TableRow>
  );
  const tableRows = invoicesData?.map((row) => (
    <TableRow key={row.id}>
      {selectRows && (
        <TableCell>
          <input
            type="checkbox"
            onClick={() =>
              setSelectedRows((prev) =>
                prev.includes(row.id)
                  ? prev.filter((e) => e !== row.id)
                  : [...prev, row.id]
              )
            }
          />
        </TableCell>
      )}
      <TableCell>
        <Text type="2">{formatDateFromMs(new Date(row.fechaEmision))}</Text>
      </TableCell>
      <TableCell>
        <Text type="2">{Utils.formatTipoComprobante(row.tipoComprobante)}</Text>
      </TableCell>
      <TableCell>
        <Styled.CellScroll>
          <Link to={`${row.id}`}>
            <Text type="2">{Utils.getClaveCorta(row.clave)}</Text>
          </Link>
        </Styled.CellScroll>
      </TableCell>
      <TableCell>
        <Text type="2">{row.nombreEmisor}</Text>
      </TableCell>
      <TableCell style={{ textAlign: "center" }}>
        <Text type="2">
          {
            row?.comprobanteElectronico?.ResumenFactura?.CodigoTipoMoneda?.CodigoMoneda
          }
        </Text>
      </TableCell>
      <TableCell style={{ textAlign: "right" }}>
        <Text type="2">
          {Utils.currencyFormat(
            row.comprobanteElectronico?.ResumenFactura?.TotalComprobante
          )}
        </Text>
      </TableCell>
      <TableCell style={{ textAlign: "right" }}>
        <Text type="2">
          {Utils.currencyFormat(
            row.comprobanteElectronico?.ResumenFactura?.TotalImpuesto
          )}
        </Text>
      </TableCell>
      <TableCell>
        <Styled.CellScroll>
          <Text type="2">
            {row.identificacionReceptor} - {row.nombreReceptor}
          </Text>
        </Styled.CellScroll>
      </TableCell>
      <TableCell>
        <Text type="2">{row.status}</Text>
      </TableCell>
      <TableCell>
        <Styled.RowActions>
          <span
            onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
              handleClick(e, row.id)
            }
          >
            ...
          </span>
        </Styled.RowActions>
      </TableCell>
    </TableRow>
  ));

  const getPageNumber = () =>
    !isNaN(Number(queryParams.get("page"))) &&
    Number(queryParams.get("page")) > 0
      ? Number(queryParams.get("page"))
      : undefined;

  const getQueryParamsObject = () => {
    const query = {
      tipo: queryParams.get("tipo") || "",
      startDate: queryParams.get("inicio") || "",
      endDate: queryParams.get("fin") || "",
      estado: queryParams.get("estado") || "",
      cliente: queryParams.get("cliente") || "",
      receptor: queryParams.get("receptor") || "",
      cuenta: queryParams.get("cuenta"),
      clave: queryParams.get("clave"),
      page:
        !isNaN(Number(queryParams.get("page"))) &&
        Number(queryParams.get("page")) > 0
          ? Number(queryParams.get("page"))
          : 1,
    };

    return Utils.removeFalsyValuesFromObject(query);
  };
  useEffect(() => {
    if (selectRows) {
      setBreadcrumbs((bc) => [
        ...bc,
        { label: invocingControlText.select, url: "/" },
      ]);
    } else if (breadcrumbs.length > 1) {
      setBreadcrumbs((bc) => bc.slice(0, 1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectRows]);

  const updateFormDataFromQuery = () => {
    const queryObject = getQueryParamsObject();
    const startDate = queryObject.startDate
      ? new Date(queryObject.startDate).toISOString()
      : "";
    const endDate = queryObject.endDate
      ? new Date(queryObject.endDate).toISOString()
      : "";

    const newFilterData = Utils.removeFalsyValuesFromObject({
      cliente: queryObject.cliente,
      estado: queryObject.estado,
      tipo: queryObject.tipo,
      startDate: startDate.split("T")[0],
      endDate: endDate.split("T")[0],
      receptor: queryObject.receptor,
    });
    filterForm.reset(newFilterData);
  };
  const handlePageLoad = async () => {
    await protectedRoute.verifyPermissions();
    await getConfig();
    updateFormDataFromQuery();
    if (claveRef?.current) {
      claveRef.current.value = queryParams.get("clave") || "";
    }
    loadInvoices();
  };

  useEffect(() => {
    updateFormDataFromQuery();
    loadInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);
  useEffect(() => {
    handlePageLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return protectedRoute.loading ? (
    <div>Loading</div>
  ) : !protectedRoute.isAllowed ? (
    protectedRoute.renderItem
  ) : (
    <Styled.Invoicing>
      <Styled.Content>
        <PageHeader
          breadcrumbs={breadcrumbs}
          title={invocingControlText.title}
        />

        <Styled.Filters>
          <Button
            variant="text"
            startIcon={<Edit />}
            onClick={() => {
              setSelectedRows([]);
              setSelectRows((val) => !val);
            }}
          >
            {invocingControlText.actions.select}
          </Button>
          <div>
            <Select
              native
              value={inboxFilter}
              onChange={(e) => {
                setInboxFilter(+e.target.value);
                setQueryParams({
                  ...getQueryParamsObject(),
                  cuenta: `${e.target.value}`,
                  page: "1",
                });
              }}
            >
              {configData?.emailInboxes?.map(
                (inbox: { id: number; email: string }, index: number) => (
                  <option
                    value={inbox.id}
                    key={inbox.id}
                    selected={index === 0}
                  >
                    <Text type="1"> {inbox.email}</Text>
                  </option>
                )
              )}
            </Select>
            <TextField
              inputRef={claveRef}
              placeholder={invocingControlText.actions.id}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search style={{ width: "24px", height: "24px" }} />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => debounceSearch(e.target.value)}
            />
            <Button
              onClick={() => setShowFilterModal(true)}
              startIcon={<Tune sx={{ transform: "rotate(-90deg)" }} />}
            >
              {invocingControlText.actions.filter}
            </Button>
          </div>
        </Styled.Filters>
        {selectRows && (
          <Styled.SelectRowsActions>
            <Button
              variant="outlined"
              size="small"
              onClick={() => updateStatusBulk(invoiceStatus.aceptado)}
            >
              {invocingControlText.popover.accept}
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={() => updateStatusBulk(invoiceStatus.rechazado)}
            >
              {invocingControlText.popover.deny}
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={() => updateStatusBulk(invoiceStatus.bloqueado)}
            >
              {invocingControlText.popover.block}
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={() => updateStatusBulk(invoiceStatus.pendiente)}
            >
              {invocingControlText.popover.pending}
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={() => updateStatusBulk(invoiceStatus.procesado)}
            >
              {invocingControlText.popover.processed}
            </Button>
          </Styled.SelectRowsActions>
        )}
        <div></div>
        <Table>
          {tableWidths}
          <TableHead>{tableHeaders}</TableHead>

          {!loading && <TableBody>{tableRows}</TableBody>}
        </Table>
        {loading && (
          <Styled.Loader>
            <CircularProgress />
          </Styled.Loader>
        )}
        <Styled.TableActions>
          <div>
            <Button
              variant="contained"
              startIcon={<OpenInNew />}
              onClick={() => generateReport()}
            >
              {invocingControlText.export.general}
            </Button>

            <Button
              variant="contained"
              startIcon={<OpenInNew />}
              onClick={() => generateReport(true)}
            >
              {invocingControlText.export.detailed}
            </Button>
          </div>
          {total ? (
            <Styled.TablePagination>
              <Text type="2">
                {getPageNumber()
                  ? `Página ${getPageNumber()} / ${Math.ceil(
                      total / DEFAULT_PAGE_SIZE
                    )} de${" "}
    ${total} resultados`
                  : ""}

                <span>
                  <ChevronLeft
                    sx={{ color: "#000" }}
                    onClick={paginationPrevious}
                  />
                  <ChevronRight
                    sx={{ color: "#000" }}
                    onClick={paginationNext}
                  />
                </span>
              </Text>
            </Styled.TablePagination>
          ) : null}
        </Styled.TableActions>
      </Styled.Content>
      <Modal open={showFilterModal}>
        <Styled.FilterModal>
          <Styled.FilterModalHeader>
            <Title type="1">Filtros</Title>
            <CancelOutlined
              onClick={() => setShowFilterModal(false)}
              sx={theme.iconSize}
            />
          </Styled.FilterModalHeader>
          <Styled.FilterModalForm>
            <Styled.LineEditItem>
              <Subtitle type="3">Tipo</Subtitle>
              <Select
                displayEmpty
                fullWidth
                native
                {...filterForm.register("tipo", {})}
                defaultValue={""}
              >
                <option value="" selected disabled>
                  <Text type="1" color={theme.colors.disabledDark}>
                    {`Tipo`}
                  </Text>
                </option>
                {configData?.tiposDeComprobantes?.map((tipo: string) => (
                  <option value={tipo}>
                    <Text type="1"> {Utils.splitCaps(tipo)}</Text>
                  </option>
                ))}
              </Select>
            </Styled.LineEditItem>
            <Styled.LineEditItem>
              <Subtitle type="3">Fecha de inicio</Subtitle>
              <TextField
                type="date"
                fullWidth
                {...filterForm.register("startDate", {})}
              />
            </Styled.LineEditItem>
            <Styled.LineEditItem>
              <Subtitle type="3">Fecha de finalización</Subtitle>
              <TextField
                type="date"
                fullWidth
                {...filterForm.register("endDate", {})}
              />
            </Styled.LineEditItem>
            <Styled.LineEditItem>
              <Subtitle type="3">Estado</Subtitle>
              <Select
                displayEmpty
                fullWidth
                native
                {...filterForm.register("estado", {})}
                defaultValue={""}
              >
                <option value="" disabled>
                  <Text type="1" color={theme.colors.disabledDark}>
                    {`Estado`}
                  </Text>
                </option>
                {configData?.estadosComprobantes?.map((t: string) => (
                  <option value={t}>
                    <Text type="1"> {t}</Text>
                  </option>
                ))}
              </Select>
            </Styled.LineEditItem>
            <Styled.LineEditItem>
              <Subtitle type="3">Proveedor</Subtitle>
              <Select
                displayEmpty
                fullWidth
                native
                {...filterForm.register("cliente", {})}
                defaultValue={""}
              >
                <option value="" disabled>
                  <Text type="1" color={theme.colors.disabledDark}>
                    {`Cliente`}
                  </Text>
                </option>
                {configData?.clientes
                    ?.sort((a, b) => {
                      if (a.text == null) return 1; // Place undefined or null values at the end
                      if (b.text == null) return -1;
                      return a.text.localeCompare(b.text);
                    })
                    .map((cliente: TextValue) => (
                        <option value={cliente.value} key={cliente.value}>
                          <Text type="1">{cliente.value} - {cliente.text}</Text>
                        </option>
                    ))}
              </Select>
            </Styled.LineEditItem>
            <Styled.LineEditItem>
              <Subtitle type="3">Receptor</Subtitle>
              <Select
                  displayEmpty
                  fullWidth
                  native
                  {...filterForm.register("receptor", {})}
                  defaultValue={""}
              >
                <option value="" disabled>
                  <Text type="1" color={theme.colors.disabledDark}>
                    {`Receptor`}
                  </Text>
                </option>
                {configData?.receptores
                    ?.sort((a, b) => {
                      if (a.text == null) return 1; // Place undefined or null values at the end
                      if (b.text == null) return -1;
                      return a.text.localeCompare(b.text);
                    })
                    .map((receptor: TextValue) => (
                        <option value={receptor.value} key={receptor.value}>
                          <Text type="1">{receptor.value} - {receptor.text}</Text>
                        </option>
                    ))}
              </Select>
            </Styled.LineEditItem>
          </Styled.FilterModalForm>
          <Styled.FilterModalFooter>
            <Button
              variant="text"
              onClick={handleClearFilters}
              startIcon={<CancelOutlined />}
            >
              {`Eliminar Filtros`}
            </Button>
            <Button onClick={handleFilterSearch} startIcon={<Search />}>
              {`Buscar`}
            </Button>
          </Styled.FilterModalFooter>
        </Styled.FilterModal>
      </Modal>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Styled.StyledPopover>
          <div onClick={() => updateStatus(invoiceStatus.aceptado)}>
            {invocingControlText.popover.accept}
          </div>
          <div onClick={() => updateStatus(invoiceStatus.rechazado)}>
            {invocingControlText.popover.deny}
          </div>
          <div onClick={() => updateStatus(invoiceStatus.bloqueado)}>
            {invocingControlText.popover.block}
          </div>
          <div onClick={() => updateStatus(invoiceStatus.pendiente)}>
            {invocingControlText.popover.pending}
          </div>
          <div onClick={() => updateStatus(invoiceStatus.procesado)}>
            {invocingControlText.popover.processed}
          </div>
        </Styled.StyledPopover>
      </Popover>
    </Styled.Invoicing>
  );
};

export default withButterfly(Invoicing);
