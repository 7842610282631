import useAuth from "./useAuth";
import axios from "../api/axios";
import { apiUrls } from "../Constants/apiUrls";

const useRefreshToken = () => {
  const { setAuth, auth } = useAuth();
  const refresh = async () => {
    const requestUrl = `${apiUrls.auth.refresh}`;
    const response = await axios.post(requestUrl, {
      email: auth?.user,
      refreshToken: auth?.refreshToken,
    });
    setAuth((prev) => {
      return { ...prev, accessToken: response.data.idToken.jwtToken };
    });
    return response.data.idToken.jwtToken;
  };
  return refresh;
};

export default useRefreshToken;
