import styled from "@emotion/styled";

const LinkWrapper = styled.span`
  & > a {
    text-decoration: none;
  }
`;

const Styled = { LinkWrapper };

export default Styled;
