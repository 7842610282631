import { useEffect, useState } from "react";
import useAuth from "./useAuth";
import { ROUTES } from "../Constants";
import { Navigate } from "react-router-dom";
import useAxios from "./useAxios";
import { apiUrls } from "../Constants/apiUrls";

const useProtectedRoute = (allowedRoles) => {
  const { auth, setAuth, setConfigData, configData } = useAuth();
  const [loading, setLoading] = useState(true);
  const [isAllowed, setIsAllowed] = useState(false);
  const [renderItem, setRenderItem] = useState(null);
  const { fetchData } = useAxios(true);

  const toLogin = <Navigate to="/" />;
  const toUnauthorized = <Navigate to={`/${ROUTES.unauthorized}`} />;

  const getConfig = async () => {
    try {
      const response = fetchData(apiUrls.catalog.catalogs, "GET");
      setConfigData(response?.data);
    } catch (error) {
      console.error("getConfig", error);
    }
  };

  const handlePermissions = (auth) => {
    if (Object.keys(auth).length === 0) {
      setRenderItem(toLogin);
    } else if (!allowedRoles?.includes(`${auth?.role}`) && auth?.user) {
      setRenderItem(toUnauthorized);
    } else {
      getConfig();
      setIsAllowed(true);
    }
  };

  const loadData = async () => {
    const storedValue = await window.localStorage.getItem("auth");
    if (storedValue) {
      const localAuth = await JSON.parse(storedValue);
      setAuth(localAuth);
      handlePermissions(localAuth);
      setLoading(false);
    } else {
      setRenderItem(toLogin);
      setLoading(false);
    }
  };

  const verifyPermissions = () => {
    setLoading(true);
    if (Object.keys(auth).length > 0) {
      handlePermissions(auth);
      setLoading(false);
    } else {
      loadData();
    }
    setLoading(false);
  };
  useEffect(() => {
    if (!loading && isAllowed) {
      getConfig();
    }
  }, [loading, isAllowed]);
  return { loading, verifyPermissions, isAllowed, renderItem };
};

export default useProtectedRoute;
