import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Subtitle, Text } from "../../../Components/Text";
import withButterfly from "../../../HOC/withButterfly";
import Styled from "./style";
import {
  CircularProgress,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import Table from "../../../Components/Table";
import { reportHistoryText } from "../../../Constants/Text";
import Utils from "../../../Utils";
import PageHeader from "../../../Components/PageHeader";
import { reportHistory } from "../../../Types/API";
import useAxios from "../../../Hooks/useAxios";
import { apiUrls } from "../../../Constants/apiUrls";
import { DEFAULT_PAGE_SIZE, MIME_TYPE, ROUTES } from "../../../Constants";
import { Link, useOutletContext } from "react-router-dom";
import useProtectedRoute from "../../../Hooks/useProtectedRoute";

const ReportHistory = () => {
  const [data, setData] = useState<reportHistory[]>();
  const [usersData, setUsersData] = useState<{ id: number; email: string }[]>(
    []
  );
  const { loading, fetchData } = useAxios(true);

  const [pageNumber, setPageNumber] = useState(1);
  const [total, setTotal] = useState(0);

  const { allowedRoles }: { allowedRoles: string[] } = useOutletContext();
  const protectedRoute = useProtectedRoute(allowedRoles);

  const loadReports = async () => {
    try {
      const res = await fetchData(
        `${apiUrls.reports.getReportHistory}?pageSize=${DEFAULT_PAGE_SIZE}&page=${pageNumber}`
      );
      setData(res?.data.results as reportHistory[]);
      setTotal(res?.data.total);
    } catch (e) {
      console.error(e);
    }
  };
  const reportsText = {
    reportsTitle: "Reportes",
    historyTitle: "Historial de Reportes",
    sapTitle: "Historial de Reportes",
    search: "Buscar",
    createReport: "Generar Reporte",
  };
  const breadcrumbs = [
    { label: `${reportsText.reportsTitle}`, url: `/${ROUTES.reports}` },
    {
      label: reportsText.historyTitle,
      url: `/${ROUTES.reports}/${ROUTES.reportHistory}`,
    },
  ];

  const loadUsers = async () => {
    try {
      const res = await fetchData(apiUrls.users.users, "GET", {}, {params: {pageSize: 99}});

      setUsersData(
        res?.data.results.map(
          ({ id, email }: { id: string; email: string }) => ({
            id,
            email,
          })
        )
      );
    } catch (e) {
      console.error(e);
    }
  };

  const paginationPrevious = () => {
    if (pageNumber === 1) return;
    setPageNumber((page) => page - 1);
  };
  const paginationNext = () => {
    if (pageNumber * DEFAULT_PAGE_SIZE >= total) return;
    setPageNumber((page) => page + 1);
  };

  useEffect(() => {
    protectedRoute.verifyPermissions();
  }, []);
  useEffect(() => {
    if (!protectedRoute.loading && protectedRoute.isAllowed && !data?.length) {
      loadReports();
      loadUsers();
    }
  }, [protectedRoute.loading, protectedRoute.isAllowed, data]);

  useEffect(() => {
    loadReports();
    loadUsers();
  }, [pageNumber]);
  const tableHeaders = (
    <TableRow>
      {[
        reportHistoryText.table.headers.date,
        reportHistoryText.table.headers.time,
        reportHistoryText.table.headers.user,
        reportHistoryText.table.headers.download,
      ].map((header, index) => (
        <TableCell key={index}>
          <Subtitle type="1">{header}</Subtitle>
        </TableCell>
      ))}
    </TableRow>
  );

  const downloadReporte = async (id: number, filename: string) => {
    try {
      const response = await fetchData(
        apiUrls.reports.downloadReport(id),
        "GET",
        {},
        { responseType: "arraybuffer" }
      );
      Utils.downloadFile(response?.data, filename, MIME_TYPE.excel);
    } catch (e) {
      console.error(e);
    }
  };

  const tableRows = data?.map((row) => (
    <TableRow key={row.id}>
      <TableCell>
        <Text type="2">{Utils.formatDateFromMs(new Date(row.createdAt))}</Text>
      </TableCell>
      <TableCell>
        <Text type="2">{Utils.formatToHHMM(new Date(row.createdAt))}</Text>
      </TableCell>

      <TableCell>
        <Text type="2">
          {usersData?.find((user) => user.id === row.createdById)?.email ||
            "Not found"}
        </Text>
      </TableCell>
      <TableCell>
        <Link
          to={"#"}
          onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
            downloadReporte(row.id, row.fileName)
          }
        >
          <Text type="2">{row.fileName}</Text>
        </Link>
      </TableCell>
    </TableRow>
  ));

  return protectedRoute.loading ? (
    <div>Loading</div>
  ) : !protectedRoute.isAllowed ? (
    protectedRoute.renderItem
  ) : (
    <Styled.Reports>
      <Styled.Content>
        <PageHeader breadcrumbs={breadcrumbs} title={reportsText.sapTitle} />
        <Styled.HR />
        <Table>
          <TableHead>{tableHeaders}</TableHead>
          {!loading && <TableBody>{tableRows}</TableBody>}
        </Table>
        {loading && (
          <Styled.Loader>
            <CircularProgress />
          </Styled.Loader>
        )}
        <Styled.TableActions>
          {total ? (
            <Styled.TablePagination>
              <Text type="2">
                Página {pageNumber} / {Math.ceil(total / DEFAULT_PAGE_SIZE)} de{" "}
                de {total} resultados
                <span>
                  <ChevronLeft
                    sx={{ color: "#000" }}
                    onClick={paginationPrevious}
                  />
                  <ChevronRight
                    sx={{ color: "#000" }}
                    onClick={paginationNext}
                  />
                </span>
              </Text>
            </Styled.TablePagination>
          ) : (
            <></>
          )}
        </Styled.TableActions>
      </Styled.Content>
    </Styled.Reports>
  );
};

export default withButterfly(ReportHistory);
