const invocingControlText = {
  title: "Control de comprobantes",
  select: "Selección",
  table: {
    headers: {
      date: "Fecha",
      type: "Tipo",
      id: "Número",
      provider: "Proveedor",
      currency: "Moneda",
      total: "Total",
      totalImpuesto: "Total impuesto",
      receptor: "Receptor",
      status: "Estado",
    },
  },
  actions: {
    select: "Seleccionar Comprobantes",
    inboxSelect: "Seleccionar buzón",
    inbox: "Buzón de recepción",
    id: "Buscar por número",
    filter: "Filtros",
  },
  export: {
    general: "Generar Reporte",
    detailed: "Generar Reporte Detallado",
  },
  status: {
    1: "Aceptada",
    2: "Rechazada",
  },

  popover: {
    accept: "Aceptar",
    deny: "Rechazar",
    block: "Bloquear",
    pending: "Pendiente",
    processed: "Procesada",
  },
};

const invoicingDetailText = {
  title: "Detalle de Comprobantes",
  titleHeader: "Datos de Cabecera",
  breadcrumb: "Detalle",
  breadcrumbParent: "Control de comprobantes",
  additionalInfo: "Líneas de Items",
  status: "Estado",
  viewInvoice: "Ver Comprobantes",
  download: "Descargar",
  downloadPdf: "Descargar PDF",
  downloadXml: "Descargar XML",
  downloadXmlHacienda: "Descargar XML Hacienda",
  edit: "Editar",
  statusActions: {
    1: "Procesada",
    2: "Por procesar",
    3: "Bloquear",
    4: "Aceptar",
    5: "Rechazar",
  },
  table: {
    headers: {
      line: "N. Línea",
      amount: "Monto",
      detail: "Detalle",
      account: "Cuenta",
      costCenter: "Centro Costo",
      posPre: "Pos Pre",
      managementCenter: "Centro Gestor",
      assignedText: "Texto Asignación",
      unit: "Unidad de Medida",
      unitValue: "Precio Unitario",
      quantity: "Cantidad",
      sub: "Monto sin impuesto",
      tax: "Impuesto",
      discount: "Descuento",
    },
  },
};

const loginText = {
  input: {
    email: { label: "Correo Electrónico", errorHelper: "Usuario incorrecto" },
    password: { label: "Contraseña", errorHelper: "Contraseña incorrecta" },
    remember_me: "Recordarme",
  },
  login: "Iniciar sesión",
};

const profileText = {
  logout: {
    button: "Cerrar Sesión",
    modal: {
      title: "Seguro que desea cerrar la sesión?",
      accept: "Sí",
      cancel: "Cancelar",
    },
  },
  password: {
    button: "Cambiar Contraseña",
    modal: {
      title: "Crear nueva contraseña",
      tooltip: (
          <div style={{ fontSize: "1.5rem" }}>
            Tamaño mínimo: 8 caracteres <br />
            Y al menos: <br />
            •1 número <br />
            •1 carácter especial <br />
            •1 letra mayúscula <br />
            •1 letra minúscula
          </div>
      ),
      instructions: "Ingrese su nueva contraseña y el código de confirmación enviado a su correo electrónico",
      new: "Nueva contraseña",
      confirm: "Confirmar contraseña",
      code: "Código de confirmación",
      accept: "Guardar",
      cancel: "Cancelar",
    },
    update: {
      success: "¡Se ha cambiado su contraseña!",
      failure: "Ha ocurrido un error",
    },
  },
};

const usersControlText = {
  title: "Usuarios",
  search: "Buscar",
  addUser: "Agregar Usuario",
  table: {
    headers: {
      name: "Nombre",
      email: "Correo",
      actions: "Acciones",
    },
    action: {
      activate: "Activar",
      deactivate: "Desactivar",
      edit: "Editar",
    },
  },
  detail: {
    title: "Información del Usuario",
    detail: "Detalle",
    info: {
      name: "Nombre",
      email: "Correo",
      password: "Contraseña",
      accounts: "Cuentas asignadas",
      role: "Rol",
      status: "Estado",
    },
  },
  edit: {
    edit: "Editar",
    title: "Editar Usuario",
    save: "Guardar",
    cancel: "Cancelar",
  },
  status: {
    active: "Activo",
    inactive: "Inactivo",
  },
};

const invoiceStatus = {
  aceptado: "Aceptado",
  rechazado: "Rechazado",
  bloqueado: "Bloqueado",
  pendiente: "Pendiente",
  procesado: "Procesado",
};

const reportHistoryText = {
  table: {
    headers: {
      date: "Fecha",
      time: "Hora",
      user: "Generado por",
      download: "Descarga",
    },
  },
};
export {
  invocingControlText,
  invoicingDetailText,
  loginText,
  profileText,
  usersControlText,
  invoiceStatus,
  reportHistoryText,
};
