import styled from "@emotion/styled";

const Reports = styled.section`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
`;
const Content = styled.section`
  padding-top: 50px;
  grid-column: 2 / 12;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  padding: 45px 0;
`;

const Search = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 32px 0 64px;
  svg {
    cursor: pointer;
  }
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TableWrapper = styled.div``;
const TableActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 64px 0 80px;
`;

const TableActionButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
`;
const TablePagination = styled.div`
  p {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    & span {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 24px;
    }
  }
`;
const CellScroll = styled.div`
  overflow-x: scroll;
`;
const Styled = {
  Reports,
  Content,
  Title,
  Search,
  TableWrapper,
  TableActions,
  TableActionButtonGroup,
  TablePagination,
  Loader,
  CellScroll,
};

export default Styled;
