import styled from "@emotion/styled";
import theme from "../../theme";

const Background = styled.section`
  background-color: ${theme.colors.white};
  position: relative;
`;

const Butterfly = styled.img`
  display: none;
  @media (min-width: ${theme.breakpoints.tablet}) {
    display: block;
    position: fixed;
    right: -12%;
    top: -135px;
    width: 50%;
    object-fit: contain;
  }
`;

const WrappedComponent = styled.div`
  position: relative;
`;

const Styled = { Background, Butterfly, WrappedComponent };
export default Styled;
