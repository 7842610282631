export const apiUrls = {
  auth: {
    authenticate: "/auth/authenticate",
    register: "/auth/register",
    confirm: "/auth/confirm",
    reset: "/auth/reset-password",
    refresh: "/auth/refresh",
    forgot: (email) => `/auth/forgot-password/${email}`,
  },
  users: {
    users: "/users",
    userById: (id) => `/users/${id}`,
    updatePassword: (id) => `/users/${id}`,
  },
  invoices: {
    getInvoices: "/comprobantes",
    getInvoicesById: (id) => `/comprobantes/${id}`,
  },
  reports: {
    getReports: "/reports",
    getReportHistory: "/reports",
    generateReportRegular: "/reports/regular",
    generateReportSAP: "/reports/sap",
    generateReportDetailed: "/reports/detallado",
    downloadReport: (id) => `/reports/${id}/file`,
  },
  catalog: {
    catalogs: "/catalogs",
  },
  inboxes: {
    usersToInboxes: "/usersToEmailInboxes",
  },
};
