import { CancelOutlined } from "@mui/icons-material";
import { Title } from "../../Components/Text";
import { useNavigate } from "react-router-dom";
import Button from "../../Components/Button";
import { ROUTES } from "../../Constants";
const Unauthorized = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(`${ROUTES.login}`);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        padding: "10%",
      }}
    >
      <CancelOutlined />
      <Title type="1">UNAUTHORIZED</Title>
      <Button onClick={goBack}>Go Back</Button>
    </div>
  );
};

export default Unauthorized;
