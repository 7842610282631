export const ROUTES = {
  invoicing: "comprobantes",
  reports: "reportes",
  reportHistory: "historial",
  users: "usuarios",
  login: "/",
  unauthorized: "unauthorized",
  detail: "detalle",
  edit: "editar",
  add: "agregar",
};

export const DEFAULT_PAGE_SIZE = 20;

export enum ROLES {
  PROVEEDOR = "Proveedor",
  COLAB = "Colaborador",
  ADMIN = "Administrador",
}

export const MIME_TYPE = {
  excel: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  pdf: "application/pdf",
  xml: "application/xml",
};
