import React from "react";
import { customTheme, primary, secondary, terciary } from "./style";
import { ButtonProps, Button as MuiButton } from "@mui/material";
import { ClassNames } from "@emotion/react";

type ButtonOptions = {} & ButtonProps;

const Button = (props: ButtonOptions) => {
  const { children, size = "medium", variant = "contained" } = props;
  const config = {
    contained: {
      style: customTheme.primary,
      classname: primary,
    },
    outlined: {
      style: customTheme.secondary,
      classname: secondary,
    },
    text: {
      style: customTheme.secondary,
      classname: terciary,
    },
  };
  const style = config[variant].style;
  const classStyle = config[variant].classname;
  return (
    <ClassNames>
      {({ css }) => (
        <MuiButton
          size={size}
          variant={variant}
          sx={style}
          className={css(classStyle)}
          fullWidth={false}
          {...props}
        >
          {children}
        </MuiButton>
      )}
    </ClassNames>
  );
};

export default Button;
