import { useParams, useOutletContext } from "react-router-dom";
import { Subtitle, Text, Title } from "../../Components/Text";
import HR from "../../Components/Divider";
import Styled from "./style";
import withButterfly from "../../HOC/withButterfly";
import {
  ArrowForward,
  Cached,
  CancelOutlined,
  Download,
  Edit,
  Error,
  HighlightOff,
  Save,
  TaskAlt,
} from "@mui/icons-material";
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import Button from "../../Components/Button";
import Table from "../../Components/Table";
import Modal from "../../Components/Modal";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import TextField from "../../Components/Input/TextField";
import ActionModal from "../../Components/ActionModal";
import theme from "../../theme";
import Alert from "../../Components/Alert";
import { invoicingDetailText } from "../../Constants/Text";
import PageHeader from "../../Components/PageHeader";
import { InvoiceType } from "../../Types/API";
import useAxios from "../../Hooks/useAxios";
import { apiUrls } from "../../Constants/apiUrls";
import Utils, { formatDateFromMs } from "../../Utils";
import { MIME_TYPE } from "../../Constants";
import useProtectedRoute from "../../Hooks/useProtectedRoute";
import Loading from "../../Components/Loading";

const InvoiceDetail = () => {
  const { id = 0 } = useParams();
  const { allowedRoles }: { allowedRoles: string[] } = useOutletContext();
  const protectedRoute = useProtectedRoute(allowedRoles);
  const [data, setData] = useState<InvoiceType | undefined>();
  const [showLineEditModal, setLineEditModal] = useState(false);
  const [selectedLine, setselectedLine] = useState(0);
  const [isLastLine, setIsLastLine] = useState(false);
  const [editHeader, setEditHeader] = useState(false);
  const [showCancelSaveModal, setShowCancelSaveModal] = useState(false);
  const [showSavedAlert, setShowSavedAlert] = useState(false);

  const defaultHeaderValues = {
    tipoDoc: data?.tipoDoc || "",
    sociedad: data?.sociedad || "",
    acreedor: data?.acreedor || "",
    textoCabecera: data?.textoCabecera || "",
    centroCosto: data?.centroCosto || "",
    posPre: data?.posPre || "",
    centroGestor: data?.centroGestor || "",
    textoAsignacion: data?.textoAsignacion || "",
    textoPosicion: data?.textoPosicion || "",

    fechaContable: data?.fechaContable
      ? Utils.formatDateYYYYMMDD(new Date(data?.fechaContable))
      : "",
    cuenta: data?.cuenta || "",
  };

  const { fetchData, loading } = useAxios(true);
  const loadInvoiceData = async (id: string | number) => {
    try {
      const invoiceResponse = (await fetchData(
        apiUrls.invoices.getInvoicesById(id)
      )) as { data: InvoiceType };
      setData(invoiceResponse.data);
    } catch (e) {
      console.error(e);
    }
  };

  const lineForm = useForm();
  const headerForm = useForm({
    defaultValues: defaultHeaderValues,
  });
  const alertSave = (time = 1000) => {
    setShowSavedAlert(true);
    setTimeout(() => setShowSavedAlert(false), time);
  };

  const handleLineSave = () => {
    alertSave();
    const data = lineForm.getValues();
    console.log("onLineSave", data);
    setLineEditModal(false);
  };
  const handleLineSaveAndNext = () => {
    alertSave(700);
    const data = lineForm.getValues();
    console.log("onLineSaveAndNext", data);
    setselectedLine((line) => (line += 1));
  };
  const handleHeaderSave = async () => {
    const headerValues = headerForm.getValues();

    try {
      const data = Utils.removeFalsyValuesFromObject({
        ...headerValues,
      });
      const fechaContable = new Date(headerValues.fechaContable);
      if (Utils.isDateValid(fechaContable)) {
        data.fechaContable = fechaContable.toISOString();
      }
      const response = await fetchData(
        apiUrls.invoices.getInvoicesById(id),
        "PATCH",
        data
      );
      if (response?.status === 200) {
        alertSave();
      }
    } catch (error) {
      console.error(error);
    }
    setEditHeader(false);
    headerForm.reset();
    loadInvoiceData(id);
  };
  const handleCancelLineSave = () => {
    setShowCancelSaveModal(false);
    setLineEditModal(false);
    lineForm.reset();
  };

  const downloadDoc = async (id: number, type: string, mime_type: string) => {
    try {
      const response = await fetchData(
        `${apiUrls.invoices.getInvoicesById(id)}/${type}`,
        "GET",
        {},
        { responseType: "arraybuffer" }
      );
      const filename = response?.headers["content-disposition"].split("=")[1];
      Utils.downloadFile(response?.data, filename, MIME_TYPE.excel);
    } catch (error) {
      console.error(error);
    }
  };

  const downloadPdf = () => {
    downloadDoc(Number(id), "pdf", MIME_TYPE.pdf);
  };
  const downloadXML = () => {
    downloadDoc(Number(id), "xml", MIME_TYPE.xml);
  };
  const downloadXMLHacienda = () => {
    downloadDoc(Number(id), "xmlhacienda", MIME_TYPE.xml);
  };

  useEffect(() => {
    protectedRoute.verifyPermissions();
  }, []);

  useEffect(() => {
    if (protectedRoute.isAllowed) {
      loadInvoiceData(id);
    }
  }, [protectedRoute.isAllowed]);

  useEffect(() => {
    const lineData = {
      account: data?.nombreEmisor,
      constCenter: "Centro Costo",
      posPre: "Pos Pre",
      managementCenter: "Centro Gestor",
      assignedText: "Texto Asignacion",
    };
    lineForm.reset(lineData);
    setIsLastLine(
      selectedLine ===
        (data?.comprobanteElectronico?.DetalleServicio?.LineaDetalle?.length ||
          1) -
          1
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLine]);

  const breadcrumbs = [
    { label: invoicingDetailText.breadcrumbParent, url: "/" },
    {
      label: invoicingDetailText.breadcrumb,
      url: `${invoicingDetailText.breadcrumb}`,
    },
  ];

  const updateStatus = async (status: string) => {
    try {
      await fetchData(apiUrls.invoices.getInvoicesById(id), "PATCH", {
        status,
      });
    } catch (error) {
      console.error(error);
    }
    loadInvoiceData(id);
  };

  const renderEditHeaderData = () => (
    <Styled.DataWrapperContent>
      <Styled.LineEditItem>
        <Subtitle type="3">Tipo Doc</Subtitle>
        <TextField
          type="text"
          placeholder="Tipo Doc"
          {...headerForm.register("tipoDoc", {
            validate: (v?: string) => !v || v.trim().length >= 1,
            onBlur: (e: any) =>
              headerForm.setValue("tipoDoc", e.target.value.trim()),
          })}
        />
      </Styled.LineEditItem>

      <Styled.LineEditItem>
        <Subtitle type="3">Sociedad</Subtitle>
        <TextField
          type="text"
          placeholder="Sociedad"
          {...headerForm.register("sociedad", {
            validate: (v: string) => !v || v.trim().length >= 1,
            onBlur: (e: any) =>
              headerForm.setValue("sociedad", e.target.value.trim()),
          })}
        />
      </Styled.LineEditItem>

      <Styled.LineEditItem>
        <Subtitle type="3">Acreedor</Subtitle>
        <TextField
          type="text"
          placeholder="Acreedor"
          {...headerForm.register("acreedor", {
            validate: (v: string) => !v || v.trim().length >= 1,
            onBlur: (e: any) =>
              headerForm.setValue("acreedor", e.target.value.trim()),
          })}
        />
      </Styled.LineEditItem>

      <Styled.LineEditItem>
        <Subtitle type="3">Text Cabecera</Subtitle>
        <TextField
          type="text"
          placeholder="Text Cabecera"
          {...headerForm.register("textoCabecera", {
            validate: (v: string) => !v || v.trim().length >= 1,
            onBlur: (e: any) =>
              headerForm.setValue("textoCabecera", e.target.value.trim()),
          })}
        />
      </Styled.LineEditItem>

      <Styled.LineEditItem>
        <Subtitle type="3">Centro Costo</Subtitle>
        <TextField
          type="text"
          placeholder="Centro Costo"
          {...headerForm.register("centroCosto", {
            validate: (v: string) => !v || v.trim().length >= 1,
            onBlur: (e: any) =>
              headerForm.setValue("centroCosto", e.target.value.trim()),
          })}
        />
      </Styled.LineEditItem>

      <Styled.LineEditItem>
        <Subtitle type="3">Pos Pre</Subtitle>
        <TextField
          type="text"
          placeholder="Pos Pre"
          {...headerForm.register("posPre", {
            validate: (v: string) => !v || v.trim().length >= 1,
            onBlur: (e: any) =>
              headerForm.setValue("posPre", e.target.value.trim()),
          })}
        />
      </Styled.LineEditItem>

      <Styled.LineEditItem>
        <Subtitle type="3">Centro Gestor</Subtitle>
        <TextField
          type="text"
          placeholder="Centro Gestor"
          {...headerForm.register("centroGestor", {
            validate: (v: string) => !v || v.trim().length >= 1,
            onBlur: (e: any) =>
              headerForm.setValue("centroGestor", e.target.value.trim()),
          })}
        />
      </Styled.LineEditItem>

      <Styled.LineEditItem>
        <Subtitle type="3">Texto Asignación</Subtitle>
        <TextField
          type="text"
          placeholder="Texto Asignación"
          {...headerForm.register("textoAsignacion", {
            validate: (v: string) => !v || v.trim().length >= 1,
            onBlur: (e: any) =>
              headerForm.setValue("textoAsignacion", e.target.value.trim()),
          })}
        />
      </Styled.LineEditItem>

      <Styled.LineEditItem>
        <Subtitle type="3">Texto Asignación</Subtitle>
        <TextField
          type="text"
          placeholder="Texto Posicion"
          {...headerForm.register("textoPosicion", {
            validate: (v: string) => !v || v.trim().length >= 1,
            onBlur: (e: any) =>
              headerForm.setValue("textoPosicion", e.target.value.trim()),
          })}
        />
      </Styled.LineEditItem>

      <Styled.LineEditItem>
        <Subtitle type="3">Fecha Contable</Subtitle>
        <TextField
          type="date"
          fullWidth
          defaultValue={data?.fechaContable}
          {...headerForm.register("fechaContable", {})}
        />
      </Styled.LineEditItem>
      <Styled.LineEditItem>
        <Subtitle type="3">Cuenta</Subtitle>
        <TextField
          type="text"
          placeholder="Cuenta"
          defaultValue={data?.cuenta}
          {...headerForm.register("cuenta", {
            validate: (v: string) => !v || v.trim().length >= 1,
            onBlur: (e: any) =>
              headerForm.setValue("cuenta", e.target.value.trim()),
          })}
        />
      </Styled.LineEditItem>
    </Styled.DataWrapperContent>
  );
  const renderDataItem = (title: string, subtitle: any) => (
    <Styled.DataWrapperItem>
      <Title type="2">{title}</Title>
      <Subtitle type="3"> {subtitle}</Subtitle>
    </Styled.DataWrapperItem>
  );
  const renderHeaderData = () => (
    <Styled.DataWrapperContent>
      {renderDataItem("Tipo Doc", data?.tipoDoc)}
      {renderDataItem("Sociedad", data?.sociedad)}
      {renderDataItem("Acreedor", data?.acreedor)}
      {renderDataItem("Text Cabecera", data?.textoCabecera)}
      {renderDataItem("Centro Costo", data?.centroCosto)}
      {renderDataItem("Pos Pre", data?.posPre)}
      {renderDataItem("Centro Gestor", data?.centroGestor)}
      {renderDataItem("Texto Asignación", data?.textoAsignacion)}
      {renderDataItem("Texto Posición", data?.textoPosicion)}
      {renderDataItem(
        "Fecha Contable",
        data?.fechaContable
          ? formatDateFromMs(new Date(`${data.fechaContable}`))
          : ""
      )}
      {renderDataItem("Cuenta", data?.cuenta)}
    </Styled.DataWrapperContent>
  );

  const renderHeaderAction = () => (
    <Styled.AdditionalActions>
      <Button
        variant="contained"
        startIcon={<Edit sx={theme.iconSize} />}
        onClick={() => {
          headerForm.reset(defaultHeaderValues);
          setEditHeader(true);
        }}
      >
        {invoicingDetailText.edit}
      </Button>
    </Styled.AdditionalActions>
  );
  const renderHeaderEditAction = () => (
    <Styled.AdditionalActions>
      <Button
        variant="outlined"
        startIcon={<CancelOutlined sx={theme.iconSize} />}
        onClick={() => {
          setEditHeader(false);
          headerForm.reset(defaultHeaderValues);
        }}
      >
        Cancelar
      </Button>
      <Button
        variant="contained"
        disabled={!headerForm.formState.isValid}
        startIcon={<Save sx={theme.iconSize} />}
        onClick={handleHeaderSave}
      >
        Guardar
      </Button>
    </Styled.AdditionalActions>
  );
  // No Linea, Unidad de Medida, Cantidad, Detalle, Monto sin impuesto, descuento, Impuesto
  const tableHeaders = [
    invoicingDetailText.table.headers.line,
    invoicingDetailText.table.headers.unit,
    invoicingDetailText.table.headers.quantity,
    invoicingDetailText.table.headers.unitValue,
    invoicingDetailText.table.headers.detail,
    invoicingDetailText.table.headers.sub,
    invoicingDetailText.table.headers.discount,
    invoicingDetailText.table.headers.tax,
    // "",
  ].map((header) => (
    <TableCell>
      <Subtitle type="1">{header}</Subtitle>
    </TableCell>
  ));
  const tableRows = data?.lineas?.map((row, index) => (
    <TableRow key={index}>
      {[
        index + 1,
        row.unidadMedida,
        row.cantidad,
        Utils.currencyFormat(row.precioUnitario),
        row.detalle,
        Utils.currencyFormat(row.montoSinImpuestos),
        Utils.currencyFormat(row.descuento),
        Utils.currencyFormat(row.impuestos),
      ].map((cell) => (
        <TableCell>
          <Text type="2">{cell}</Text>
        </TableCell>
      ))}

      {/* <TableCell>
        <Button
          variant="text"
          onClick={() => {
            setLineEditModal(true);
            setselectedLine(index);
            lineForm.reset(row);
          }}
          startIcon={<Edit sx={theme.iconSize} />}
        >
          {invoicingDetailText.edit}
        </Button>
      </TableCell> */}
    </TableRow>
  ));

  return loading ? (
    <Loading />
  ) : (
    <Styled.InvoiceDetail>
      <Styled.ContentDetail>
        <PageHeader
          breadcrumbs={breadcrumbs}
          title={invoicingDetailText.title}
        />

        <Styled.Status>
          <Title type="2">{invoicingDetailText.status}:</Title>
          <span>
            {/* <Cached sx={theme.iconSize} /> */}
            <Subtitle type="3">{data?.status}</Subtitle>
          </span>
        </Styled.Status>
        <Styled.DataWrapper>
          <Title type="2">{invoicingDetailText.titleHeader}</Title>
          <Styled.DataWrapperContent></Styled.DataWrapperContent>
          {renderDataItem("Clave", data?.clave)}
          <Styled.DataWrapperContent>
            {renderDataItem(
              "Fecha Emisión",
              formatDateFromMs(new Date(`${data?.fechaEmision}`))
            )}
            {renderDataItem("Moneda", data?.moneda)}
            {renderDataItem(
              "Importe total",
              Utils.currencyFormat(data?.importeTotal)
            )}
            {renderDataItem(
              "Total Venta",
              Utils.currencyFormat(data?.totalVenta)
            )}
            {renderDataItem(
              "Total Exento",
              Utils.currencyFormat(data?.totalExento)
            )}
            {renderDataItem(
              "Total Gravado",
              Utils.currencyFormat(data?.totalGravado)
            )}
            {renderDataItem(
              "Total Impuesto",
              Utils.currencyFormat(data?.totalImpuesto)
            )}
            {renderDataItem(
              "Total Exonerado",
              Utils.currencyFormat(data?.totalExonerado)
            )}
            {renderDataItem(
              "Total Descuentos",
              Utils.currencyFormat(data?.totalDescuentos)
            )}
            {renderDataItem(
                "Total Otros Cargos",
                Utils.currencyFormat(data?.totalOtrosCargos)
            )}
          </Styled.DataWrapperContent>
          <Styled.DataWrapperContent>
            {renderDataItem("Tipo comprobante", data?.tipoComprobante)}
          </Styled.DataWrapperContent>
          <Styled.DataWrapperContent>
            {renderDataItem("Emisor", data?.comprobanteElectronico?.Emisor?.Nombre)}
            {renderDataItem("Cédula Emisor", data?.comprobanteElectronico?.Emisor?.Identificacion?.Numero)}
          </Styled.DataWrapperContent>
          <Styled.DataWrapperContent>
            {renderDataItem("Receptor", data?.comprobanteElectronico?.Receptor?.Nombre)}
            {
              data?.comprobanteElectronico?.Receptor?.IdentificacionExtranjero
                  ? renderDataItem("Identificación Extranjero Receptor", data?.comprobanteElectronico?.Receptor?.IdentificacionExtranjero)
                  : renderDataItem("Cédula Receptor", data?.comprobanteElectronico?.Receptor?.Identificacion?.Numero) }
          </Styled.DataWrapperContent>

          <HR />
          {editHeader ? renderEditHeaderData() : renderHeaderData()}
          {editHeader ? renderHeaderEditAction() : renderHeaderAction()}
        </Styled.DataWrapper>
        <Styled.InvoiceActions>
          <Button
            variant="outlined"
            startIcon={<Download sx={theme.iconSize} />}
            onClick={downloadPdf}
          >
            {invoicingDetailText.downloadPdf}
          </Button>
          <Button
            variant="outlined"
            startIcon={<Download sx={theme.iconSize} />}
            onClick={downloadXML}
          >
            {invoicingDetailText.downloadXml}
          </Button>
          <Button
            variant="outlined"
            startIcon={<Download sx={theme.iconSize} />}
            onClick={downloadXMLHacienda}
          >
            {invoicingDetailText.downloadXmlHacienda}
          </Button>
        </Styled.InvoiceActions>
        <Styled.TitleUnderlined>
          <Title type="2">{invoicingDetailText.additionalInfo}</Title>
        </Styled.TitleUnderlined>
        <Styled.TableWrapper>
          <Table stickyHeader>
            <TableHead>
              <TableRow>{tableHeaders}</TableRow>
            </TableHead>
            <TableBody>{tableRows}</TableBody>
          </Table>
        </Styled.TableWrapper>
        <Styled.StatusActions>
          <Button
            // disabled
            variant="outlined"
            onClick={() => updateStatus("Pendiente")}
            startIcon={<Cached sx={theme.iconSize} />}
          >
            {invoicingDetailText.statusActions[2]}
          </Button>
          <Button
            variant="outlined"
            startIcon={<TaskAlt sx={theme.iconSize} />}
            onClick={() => updateStatus("Aceptado")}
          >
            {invoicingDetailText.statusActions[4]}
          </Button>
          <Button
            variant="outlined"
            startIcon={<HighlightOff sx={theme.iconSize} />}
            onClick={() => updateStatus("Rechazado")}
          >
            {invoicingDetailText.statusActions[5]}
          </Button>
        </Styled.StatusActions>
      </Styled.ContentDetail>
      <Modal
        open={showLineEditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Styled.LineEditWrapper>
          <Styled.LineEditHeader>
            <Title type="1">Línea {selectedLine + 1}</Title>
            <CancelOutlined
              sx={theme.iconSize}
              onClick={() => setShowCancelSaveModal(true)}
            />
          </Styled.LineEditHeader>
          <Styled.LineEditContent>
            <Styled.LineEditDetail>
              <span>
                <Title type="3">
                  {invoicingDetailText.table.headers.detail}
                </Title>
                <Subtitle type="3">
                  {
                    data?.comprobanteElectronico?.DetalleServicio?.LineaDetalle[
                      selectedLine
                    ]?.Detalle
                  }
                </Subtitle>
              </span>
              <span>
                <Title type="3">
                  {invoicingDetailText.table.headers.amount}
                </Title>
                <Subtitle type="3">
                  {Math.round(
                    (data?.comprobanteElectronico?.DetalleServicio
                      ?.LineaDetalle[selectedLine]?.MontoTotalLinea || 0) * 100
                  ) / 100}
                </Subtitle>
              </span>
            </Styled.LineEditDetail>
            <Styled.LineEditForm>
              <Styled.LineEditFormFields>
                <Styled.LineEditItem>
                  <Subtitle type="3">Cuenta</Subtitle>
                  <TextField
                    type="text"
                    placeholder="Cuenta"
                    {...lineForm.register("account", {
                      validate: (v: string) => v?.trim().length >= 1,
                    })}
                  />
                </Styled.LineEditItem>
                <Styled.LineEditItem>
                  <Subtitle type="3">Centro Costo</Subtitle>
                  <TextField
                    disabled
                    type="text"
                    placeholder="Centro Costo"
                    {...lineForm.register("costCenter", {
                      validate: (v: string) => v?.trim().length >= 1,
                    })}
                  />
                </Styled.LineEditItem>
                <Styled.LineEditItem>
                  <Subtitle type="3">Pos Pre</Subtitle>
                  <TextField
                    type="text"
                    placeholder="Pos Pre"
                    {...lineForm.register("posPre", {
                      validate: (v: string) => v?.trim().length >= 1,
                    })}
                  />
                </Styled.LineEditItem>
                <Styled.LineEditItem>
                  <Subtitle type="3">Centro Gestor</Subtitle>
                  <TextField
                    type="text"
                    placeholder="Centro Gestor"
                    {...lineForm.register("managementCenter", {
                      validate: (v: string) => v?.trim().length >= 1,
                    })}
                  />
                </Styled.LineEditItem>
                <Styled.LineEditItem>
                  <Subtitle type="3">Texto Asignación</Subtitle>
                  <TextField
                    type="text"
                    placeholder="Texto Asignación"
                    {...lineForm.register("assignedText", {
                      validate: (v: string) => v?.trim().length >= 1,
                    })}
                  />
                </Styled.LineEditItem>
              </Styled.LineEditFormFields>
              <Styled.LineEditActions>
                <Button
                  // type="submit"
                  onClick={handleLineSave}
                  variant="outlined"
                  disabled={!lineForm.formState.isValid}
                  startIcon={<Save sx={theme.iconSize} />}
                >
                  Guardar
                </Button>
                {!isLastLine && (
                  <Button
                    onClick={handleLineSaveAndNext}
                    variant="contained"
                    disabled={!lineForm.formState.isValid}
                    startIcon={<ArrowForward sx={theme.iconSize} />}
                  >
                    Guardar y continuar
                  </Button>
                )}
              </Styled.LineEditActions>
            </Styled.LineEditForm>
          </Styled.LineEditContent>
        </Styled.LineEditWrapper>
      </Modal>
      <ActionModal
        showModal={showCancelSaveModal}
        title={`¿Seguro que desea salir?`}
        subtitle={`No se guardarán los cambios.`}
        onCancel={() => setShowCancelSaveModal(false)}
        onOk={handleCancelLineSave}
        icon={
          <Styled.ErrorIcon>
            <Error
              style={{
                color: theme.colors.alert,
                width: "56px",
                height: "56px",
              }}
            />
          </Styled.ErrorIcon>
        }
      />

      <Alert
        show={showSavedAlert}
        message={`¡La información se ha agregado!`}
      />
    </Styled.InvoiceDetail>
  );
};

export default withButterfly(InvoiceDetail);
