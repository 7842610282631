import theme from "../../theme";
import { css } from "@emotion/react";

const customTheme = {
  primary: {
    bgcolor: theme.colors.accent,
    color: theme.colors.white,
    ":focus": {
      bgcolor: theme.colors.accent,
      color: theme.colors.white,
    },
    ":hover": {
      bgcolor: theme.colors.accent,
      opacity: 0.6,
    },
    ":disabled": {
      background: theme.colors.disabledLight,
      color: theme.colors.disabledDark,
    },
  },
  secondary: {
    borderColor: theme.colors.secondary,
    color: theme.colors.secondary,
    ":hover": {
      opacity: 0.4,
    },
    ":disabled": {
      borderColor: theme.colors.disabledLight,
      color: theme.colors.disabledDark,
    },
  },
  terciary: {
    color: theme.colors.secondary,
    ":hover": {
      color: theme.colors.secondary,
      textDecoration: "underline #000000",
    },
    ":disabled": {
      borderColor: theme.colors.disabledLight,
      color: theme.colors.disabledDark,
    },
  },
};
const btnBase = css`
  width: min-content;
  white-space: nowrap;
  font-size: 1.6rem;
  &:hover {
    cursor: pointer;
  }
`;
const primary = css`
  ${btnBase}
`;
const secondary = css`
  ${btnBase}
`;
const terciary = css`
  ${btnBase}
  border-radius: 0;
  &:hover {
    border-bottom: 1px solid ${theme.colors.secondary};
    color: ${theme.colors.secondary};
    opacity: 1;
    background: none;
  }
`;
export { customTheme, primary, secondary, terciary };
