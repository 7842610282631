import colors from "./colors";
import breakpoints from "./breakpoints";

const theme = {
  colors,
  breakpoints,
  iconSize: { width: "24px", height: "24px" },
};

export default theme;
