import Styled from "./style";
import { Modal as MuiModal } from "@mui/material";
import { ErrorOutline, TaskAlt } from "@mui/icons-material";
import theme from "../../theme";
import { Subtitle } from "../Text";

type AlertOptions = {
  show: boolean;
  message: string;
  severity?: string;
};

const Alert = (props: AlertOptions) => {
  const { severity = "success" } = props;
  const iconStyle = {
    color: theme.colors.white,
    width: "56px",
    height: "56px",
  };
  const Content =
    severity === "error" ? Styled.AlertErrorContent : Styled.AlertContent;
  return (
    <MuiModal {...props} open={props.show}>
      <Styled.Alert>
        <Content>
          {severity === "error" ? (
            <ErrorOutline style={iconStyle} />
          ) : (
            <TaskAlt style={iconStyle} />
          )}

          <Subtitle type="1" color={theme.colors.white}>
            {props.message}
          </Subtitle>
        </Content>
      </Styled.Alert>
    </MuiModal>
  );
};

export default Alert;
