import { useState } from "react";
import axios from "../api/axios";
import useAxiosPrivate from "./useAxiosPrivate";

const useAxios = (isPrivate) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [headers, setHeaders] = useState(null);
  const axiosPrivate = useAxiosPrivate();
  const axiosInstance = isPrivate ? axiosPrivate : axios;
  const fetchData = async (url, method = "GET", body = {}, config = {}) => {
    const requestUrl = `${url}`;
    setLoading(true);

    try {
      let response;
      switch (method.toUpperCase()) {
        case "GET":
          response = await axiosInstance.get(requestUrl, config);
          break;
        case "POST":
          response = await axiosInstance.post(requestUrl, body, config);
          break;
        case "PUT":
          response = await axiosInstance.put(requestUrl, body, config);
          break;
        case "PATCH":
          response = await axiosInstance.patch(requestUrl, body, config);
          break;
        case "DELETE":
          response = await axiosInstance.delete(requestUrl, {
            ...config,
            data: body,
          });
          break;
        default:
          throw new Error(`Unsupported HTTP method: ${method}`);
      }
      setData(response.data);
      setLoading(false);
      setError(null);
      return response;
    } catch (error) {
      setError(error);
      setLoading(false);
      setData(null);
      console.error(error);
    }
  };

  return { data, loading, error, headers, fetchData };
};

export default useAxios;
