import React, { ReactNode } from "react";
import { TableStyles } from "./style";

import { TableProps, Table as TableMui, TableContainer } from "@mui/material";
import { ClassNames } from "@emotion/react";

type TableOptions = {
  children: ReactNode;
} & TableProps;

const Table = ({ children }: TableOptions) => {
  return (
    <ClassNames>
      {({ css }) => (
        <TableContainer className={css(TableStyles)}>
          <TableMui size="small">{children}</TableMui>
        </TableContainer>
      )}
    </ClassNames>
  );
};

export default Table;
