import {
  ChevronLeft,
  ChevronRight,
  OpenInNew,
  Search,
} from "@mui/icons-material";
import TextField from "../../Components/Input/TextField";
import { Subtitle, Text } from "../../Components/Text";
import withButterfly from "../../HOC/withButterfly";
import Styled from "./style";
import theme from "../../theme";
import {
  CircularProgress,
  InputAdornment,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Button from "../../Components/Button";
import { useEffect, useState } from "react";
import Table from "../../Components/Table";
import { invocingControlText } from "../../Constants/Text";
import { useOutletContext } from "react-router-dom";
import Utils, { formatDateFromMs } from "../../Utils";
import PageHeader from "../../Components/PageHeader";
import { InvoiceType } from "../../Types/API";
import useAxios from "../../Hooks/useAxios";
import { apiUrls } from "../../Constants/apiUrls";
import { useNavigate } from "react-router-dom";
import { DEFAULT_PAGE_SIZE, MIME_TYPE, ROUTES } from "../../Constants";
import useProtectedRoute from "../../Hooks/useProtectedRoute";
import useDebounce from "../../Hooks/useDebounce";

const Reports = () => {
  const [data, setData] = useState<InvoiceType[]>();
  const { loading, fetchData } = useAxios(true);

  const [pageNumber, setPageNumber] = useState(1);
  const [total, setTotal] = useState(0);

  const { allowedRoles }: { allowedRoles: string[] } = useOutletContext();
  const protectedRoute = useProtectedRoute(allowedRoles);

  const loadReports = async () => {
    const res = await fetchData(
      `${apiUrls.invoices.getInvoices}?pageSize=${DEFAULT_PAGE_SIZE}&page=${pageNumber}&clave=${searchValue}&estado=ACEPTADO`
    );
    setData(res?.data.results as InvoiceType[]);
    setTotal(res?.data.total);
  };
  const navigate = useNavigate();
  const debounce = useDebounce();
  const [searchValue, setSearchValue] = useState("");
  const reportsText = {
    title: "Reportes",
    sapTitle: "Reporte SAP",
    search: "Buscar",
    createReport: "Generar Reporte",
    goToHistory: "Ver Historial",
  };
  const breadcrumbs = [{ label: reportsText.title, url: "/reportes" }];

  const paginationPrevious = () => {
    if (pageNumber === 1) return;
    setPageNumber((page) => page - 1);
  };
  const paginationNext = () => {
    if (pageNumber * DEFAULT_PAGE_SIZE >= total) return;
    setPageNumber((page) => page + 1);
  };

  const tableWidths = (
    <colgroup>
      <col style={{ width: "6%" }} /> {/* Fecha */}
      <col style={{ width: "12%" }} /> {/* Tipo */}
      <col style={{ width: "15%" }} /> {/* Clave */}
      <col style={{ width: "20%" }} /> {/* Proveedor */}
      <col style={{ width: "6%", textAlign: "center" }} /> {/* Moneda */}
      <col style={{ width: "10%" }} /> {/* Total */}
      <col style={{ width: "10%" }} /> {/* Total Impuesto */}
      <col style={{ width: "17%" }} /> {/* Receptor */}
      <col style={{ width: "8%" }} /> {/* Status */}
      <col style={{ width: "2%" }} /> {/* Opciones */}
    </colgroup>
  );
  const generateReport = async () => {
    const createReportResponse = (await fetchData(
      apiUrls.reports.generateReportSAP,
      "POST"
    )) as { data: { message: string; id: number } };
    try {
      const res = await fetchData(
        apiUrls.reports.downloadReport(createReportResponse.data.id),
        "GET",
        {},
        { responseType: "arraybuffer" }
      );

      const filename = res?.headers["content-disposition"].split("=")[1];
      Utils.downloadFile(res?.data, filename, MIME_TYPE.excel);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    protectedRoute.verifyPermissions();
  }, []);
  useEffect(() => {
    loadReports();
  }, [pageNumber, searchValue]);

  // useEffect(() => {
  //   loadReports();
  // }, [pageNumber,]);
  const tableHeaders = (
    <TableRow>
      {[
        invocingControlText.table.headers.date,
        invocingControlText.table.headers.type,
        invocingControlText.table.headers.id,
        invocingControlText.table.headers.provider,
        invocingControlText.table.headers.currency,
        invocingControlText.table.headers.total,
        invocingControlText.table.headers.totalImpuesto,
        invocingControlText.table.headers.status,
      ].map((header, index) => (
        <TableCell key={index}>
          <Subtitle type="1">{header}</Subtitle>
        </TableCell>
      ))}
    </TableRow>
  );

  const tableRows = data?.map((row) => (
    <TableRow key={row.id}>
      <TableCell>
        <Text type="2">{formatDateFromMs(new Date(row.fechaEmision))}</Text>
      </TableCell>
      <TableCell>
        <Text type="2">{Utils.formatTipoComprobante(row.tipoComprobante)}</Text>
      </TableCell>
      <TableCell>
        <Styled.CellScroll>
          {/* <Link to={`${row.id}`}> */}
          <Text type="2">{Utils.getClaveCorta(row.clave)}</Text>
          {/* </Link> */}
        </Styled.CellScroll>
      </TableCell>
      <TableCell>
        <Text type="2">{row.nombreEmisor}</Text>
      </TableCell>
      <TableCell>
        <Text type="2">
          {
            row?.comprobanteElectronico?.ResumenFactura?.CodigoTipoMoneda
              ?.CodigoMoneda
          }
        </Text>
      </TableCell>
      <TableCell>
        <Text type="2">
          {Utils.currencyFormat(
            row.comprobanteElectronico?.ResumenFactura?.TotalComprobante
          )}
        </Text>
      </TableCell>
      <TableCell>
        <Text type="2">
          {Utils.currencyFormat(
            row.comprobanteElectronico?.ResumenFactura?.TotalImpuesto
          )}
        </Text>
      </TableCell>
      <TableCell>
        <Text type="2">{row.status}</Text>
      </TableCell>
    </TableRow>
  ));

  return protectedRoute.loading ? (
    <div>Loading</div>
  ) : !protectedRoute.isAllowed ? (
    protectedRoute.renderItem
  ) : (
    <Styled.Reports>
      <Styled.Content>
        <PageHeader breadcrumbs={breadcrumbs} title={reportsText.sapTitle} />

        <Styled.Search>
          <TextField
            onChange={(e) => debounce(e.target.value, setSearchValue)}
            placeholder={reportsText.search}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search sx={theme.iconSize} />
                </InputAdornment>
              ),
            }}
          />
        </Styled.Search>
        <Table>
          <TableHead>{tableHeaders}</TableHead>
          {!loading && <TableBody>{tableRows}</TableBody>}
        </Table>
        {loading && (
          <Styled.Loader>
            <CircularProgress />
          </Styled.Loader>
        )}
        <Styled.TableActions>
          <Styled.TableActionButtonGroup>
            <Button
              variant="contained"
              startIcon={<OpenInNew sx={theme.iconSize} />}
              onClick={generateReport}
            >
              {reportsText.createReport}
            </Button>
            <Button
              variant="outlined"
              startIcon={<OpenInNew sx={theme.iconSize} />}
              onClick={() => navigate(`${ROUTES.reportHistory}`)}
            >
              {reportsText.goToHistory}
            </Button>
          </Styled.TableActionButtonGroup>
          {total ? (
            <Styled.TablePagination>
              <Text type="2">
                Página {pageNumber} / {Math.ceil(total / DEFAULT_PAGE_SIZE)} de{" "}
                de {total} resultados
                <span>
                  <ChevronLeft
                    sx={{ color: "#000" }}
                    onClick={paginationPrevious}
                  />
                  <ChevronRight
                    sx={{ color: "#000" }}
                    onClick={paginationNext}
                  />
                </span>
              </Text>
            </Styled.TablePagination>
          ) : (
            <></>
          )}
        </Styled.TableActions>
      </Styled.Content>
    </Styled.Reports>
  );
};

export default withButterfly(Reports);
