import styled from "@emotion/styled";
import theme from "../../theme";
const Alert = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(2px);
`;

const alertBase = `padding: 50px 80px;
border-radius: 4px;
color: ${theme.colors.white};
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 32px;`;
const AlertContent = styled.div`
  background-color: ${theme.colors.accent};
  ${alertBase}
`;
const AlertErrorContent = styled.div`
  background-color: ${theme.colors.alert};
  ${alertBase}
`;
const Styled = { Alert, AlertContent, AlertErrorContent };

export default Styled;
