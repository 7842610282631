import React from "react";
import Nav from "../../Components/Navigation";
import { Outlet } from "react-router-dom";

type RequireAuthProps = {
  allowedRoles: (string | number)[];
};

const MainLayout = ({ allowedRoles }: RequireAuthProps) => {
  return (
    <>
      <Nav />
      <Outlet context={{ allowedRoles }} />
    </>
  );
};

export default MainLayout;
